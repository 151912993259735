
<div style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: space-between;">
    <div>
        <div class="title">
            <span>{{ data.title }}</span>
        </div>
        <div class="content" [innerHTML]="data.content"></div>
    </div>
    
    <ng-container>
        <div class="flex-column-100">
            <div *ngFor="let contact of data.contacts" class="addresses-wrapper">
                <div class="icon-wrapper">
                    <mat-icon class="person-icon-style">person</mat-icon>
                </div>
                <span class="row-cell" style="width: 22.5%" [matTooltip]="contact.lastname || ''">{{contact.lastname || '' | ellipsis:10}}</span>
                <span class="row-cell" style="width: 22.5%" [matTooltip]="contact.firstname || ''">{{contact.firstname || '' | ellipsis:10}}</span>
                <span class="row-cell" style="width: 22.5%" [matTooltip]="contact.company || ''">{{contact.company || '' | ellipsis:10}}</span>
            </div>
        </div>
    </ng-container>
    
    <div class="button-container">
        <button mat-button (click)="confirm()" for="valider">Valider</button>
        <button mat-button mat-dialog-close for="annuler">Annuler</button>
    </div>    
</div>
