import { Contact, RepertoryDataSource } from "../models/repertory-view.model";

export const initRepertoryDataSource = [
    {
        letter: 'A',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'B',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'C',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'D',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'E',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'F',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'G',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'H',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'I',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'J',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'K',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'L',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'M',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'N',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'O',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'P',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'Q',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'R',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'S',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'T',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'U',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'V',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'W',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'X',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'Y',
        hasBeenLoaded: false,
        datas: []
    },
    {
        letter: 'Z',
        hasBeenLoaded: false,
        datas: []
    }
]

export function initCurrentContact(): Contact {
    return {
        lastname: '',
        _id: '',
        firstname: '',
        email: '',
        tel: '',
        company: '',
        createdAt: '',
        customer: '',
        updatedAt: ''
    }
}

export function fillContactsByLetter(dataSource, data): RepertoryDataSource[] {
    dataSource.forEach(contactsBlock => {
        data.datas.forEach(resContactBlock => {
            if ((contactsBlock.letter.toLowerCase() === resContactBlock.letter.toLowerCase()) && resContactBlock.datas) {
                contactsBlock.datas = resContactBlock.datas;
            }
        })
    });

    return dataSource;
}

export function removeContactsAfterDeletion(contactIds: string[], dataSource): RepertoryDataSource[] {
    contactIds.forEach((contactId) => {
        dataSource.forEach(data => {
          data.datas.forEach(contact => {
            if (contact._id === contactId) {
              data.datas = data.datas.filter(contact => { contact._id !== contactId }); 
            }
          });
        });
    });

    return dataSource;
}

export function handleCheckedContacts($event: {checked: boolean, contact: Contact}, contactsToDelete: Contact[], isDeleteButtonDisabled?: boolean): {contactsToDelete: Contact[], isDeleteButtonDisabled: boolean} {
    if ($event.checked) {
        const findContact = contactsToDelete.find(contact => contact._id === $event.contact._id);
        if (!findContact) {
          contactsToDelete.push($event.contact);
        }
      }
      if (!$event.checked) {
        const findContact = contactsToDelete.find(contact => contact._id === $event.contact._id);
        if (findContact) {
          contactsToDelete = contactsToDelete.filter(contact => contact._id !== $event.contact._id);
        }
      }
      if (contactsToDelete.length === 0) {
        isDeleteButtonDisabled = true;
      }
      if (contactsToDelete.length > 0) {
        isDeleteButtonDisabled = false;
      }
  
      return {
        contactsToDelete,
        isDeleteButtonDisabled
      }
}

export function handleCheckedContactsToAddToEvent($event: {checked: boolean, contact: Contact}, contactsToAddToEvent: Contact[]): {contactsToAddToEvent: Contact[]} {
    if ($event.checked) {
        const findContact = contactsToAddToEvent.find(contact => contact._id.toString() === $event.contact._id.toString());
        if (!findContact) {
            contactsToAddToEvent.push($event.contact);
        }
    }
    if (!$event.checked) {
        const findContact = contactsToAddToEvent.find(contact => contact._id.toString() === $event.contact._id.toString());
        if (findContact) {
            contactsToAddToEvent = contactsToAddToEvent.filter(contact => contact._id.toString() !== $event.contact._id.toString());
        }
    }
    return {
    contactsToAddToEvent
    }
}
