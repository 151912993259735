import { MomentWithBundle, Participant } from '@features/ops/models/bundle.model';
import { Dashboard } from '@features/ops/models/ops-dashboard.model';
import {
  Addresses,
  Customer,
  CustomerProfil,
  Datas,
  Deliveries,
  DeliveryBlockConfig,
  DeliveryDropListConfig,
  Events
} from '@shared/models/shared.model';
import { UserData } from '@shared/models/user.model';
import * as moment from 'moment';

/**
 * Map delivery dates by taking all delivery date
 * @param customers - deliveries with customer info
 */
export function setDeliveryDates(customers: Customer[]): string[] {
  const allDates: string[] = customers.filter(c => c?.logistic?.delivery?.date).map(c => c.logistic.delivery.date);
  /**
   * filter uniq values in array
   * new Set([1, 2, 1, 3, 2]) => [1, 2, 3]
   */
  return [...new Set(allDates)]
    .sort((a, b) => moment(a).diff(b));
}

export function mapCustomers(customers: Customer[]): Array<Customer & { commentToDisplay: string, commentToDisplayTooltip: string }> {
  return customers.map(customer => {
    const commentToDisplay = [...customer.chefingComments || [], customer.comment]?.filter(s => s)?.join(', ');
    const commentToDisplayTooltip = commentToDisplay.replace(', ', '\n');
    return {
      ...customer,
      commentToDisplay,
      commentToDisplayTooltip
    };
  }) || [];
}

export function getSavPrice(customersData: Datas[], status: string): number {
  return customersData.find((data) => data.status === status).savPrice;
}

export function initDeliveryBlockConfig(title: string, label: string, color: string, customers: Customer[], savPrice: number): DeliveryBlockConfig {
  return {
    title,
    label,
    color,
    customers,
    savPrice
  };
}

export function initDeliveryDropListConfig(dropListName: string, dropListNameShort: string, dropListConnectedTo: string[], dropListArrayName: Customer[]): DeliveryDropListConfig {
  return {
    dropListName,
    dropListNameShort,
    dropListConnectedTo,
    dropListArrayName
  };
}

export function initAddressesDataSource(): Addresses {
  return {
    _id: '',
    datas: [],
    fees: 0,
    limitDate: '',
    nbAddressesExpected: 0,
    totalCount: 0,
    zones: [],
    sav: [],
    totalFees: 0,
    company: '',
    validatedAddresses: 0,
    savAddresses: 0,
    customerAddresses: 0,
    promoterId: ''
  };
}

export function initDeliveriesDataSource(): Deliveries {
  return {
    datas: [],
    sav: [],
    fees: 0,
    savPrice: 0,
    limitDate: ''
  };
}

export function initUserDataSource(): UserData {
  return {
    email: '',
    firstname: '',
    lastname: '',
    picture: '',
    tel: '',
    kinds: []
  };
}

export function initCustomerDataSource(): CustomerProfil {
  return {
    firstname: '',
    email: '',
    lastname: '',
    picture: '',
    tel: '',
    addresses: [],
    events: []
  };
}

export function initEventsDataSource(): Events {
  return {
    datas: [],
    users: [],
    totalCount: 0
  };
}

export const dashBoardDefaultDataSource: Dashboard = {
  detailedScenario: {
    information: [],
    moments: []
  },
  kpiValues: {
    deliveredAddressesCount: 0,
    notValidatedAddressesCount: 0,
    onProgressAddressesCount: 0,
    pendingAddressesCount: 0,
    savAddressesCount: 0,
    scheduledAddressesCount: 0,
    validatedAddressesCount: 0,
    validatedAddressesWithHighRiskCount: 0,
    validatedAddressesWithMediumRiskCount: 0,
    validatedAddressesWithLowRiskCount: 0,
    notValidatedAddressesWithHighRiskCount: 0,
    notValidatedAddressesWithMediumRiskCount: 0,
    notValidatedAddressesWithLowRiskCount: 0,
    parcelRelayAddressesCount: 0,
    nbAddressesMissing: 0,
    nbAddressesExpected: 0,
    addressesOnSav: []
  }
};

export function setWarning(customers: Customer[]): boolean {
  let isWarningDisplayed = false;
  if (customers.length > 0) {
    customers.forEach((customer) => {
      if (!customer.sav || customer.sav === '') {
        isWarningDisplayed = true;
      }
    });
  } else {
    isWarningDisplayed = false;
  }

  return isWarningDisplayed;
}

export function returnSearchParams(searchString, pageReceived): { page: number, q: string } {
  let page: number = 0;
  let q: string = '';

  if (searchString) {
    if (searchString.length >= 3) {
      page = 0;
      q = searchString;
    } else {
      page = pageReceived || 0;
    }
  } else {
    page = pageReceived;
  }

  return { page, q };
}

export const exportParticipantCsv = (allParticipants: Participant[]) => {
  let csv = 'Prénom;Nom;Téléphone;Email;Informations complémentaires;Spécificités;Numéro de dossier;Nom d\'entreprise;\n';

  allParticipants.forEach(p => {
    const participantDetail = p; // .value;
    csv += `${participantDetail.firstname || '-'};${participantDetail.lastname || '-'};${participantDetail.tel || '-'};${participantDetail.email || '-'};${'-'};${'-'};${'-'};${'-'}`;
    csv += `\n`;
  });

  return csv;
};

export const exportBundlesCsv = (moments: MomentWithBundle[]) => {
  let csv = 'Adresse;Code Postal;Ville;Pays;Zone;Nombre de Participant;Typologie de moment;Nom du moment;Date du moment;Heure debut du moment;Heure fin du moment;Espace\n';

  moments.forEach(m => {
    if (m.bundles.length) {
      m.bundles.forEach(b => {
        if (b.address && b.address.value) {
          const addressDetail = b.address.value;
          csv += `${addressDetail.value || '-'};${addressDetail.zipCode || '-'};${addressDetail.city || '-'};${addressDetail.country || '-'};${typeof addressDetail.zone === 'object' ? addressDetail.zone?.name : addressDetail.zone || '-'};${b.participants.length};${m.value || '-'};${m.name || '-'};${moment(m.date).format('DD/MM/YYYY') || '-'};${m.slot?.start || '-'};${m.slot?.end || '-'};${m.description || '-'}`;
          csv += `\n`;
        }
      });
    }
  });

  return csv;
};

export const exportCsv = (customers, role: string, event, savs, zones): string => {
  let csv = `Prénom;Nom;Téléphone;Email;Adresse;Code Postal;Ville;Pays;Informations complémentaires;En cas d\'absence contact sur place;Téléphone sur place;Spécificités;Alertes;Date début enlèvement;Date fin enlèvement;Créneau enlèvement;Date début livraison;Date fin livraison;Créneau de livraison;Statut;Transporteur;Numéro de dossier;Nom d\'entreprise;Statut SAV;Commentaire Libre;Commentaire(s) interne;Statut de l\'adresse\n`;
  // csv += role === 'admin' ? ';Zone;Spécificités interne; id\n' : '\n';
  if (role === 'admin') {
    const csvArray = csv.split(';');
    csvArray.splice(csvArray.indexOf('Statut SAV') + 1, 0, 'Zone');
    csvArray.splice(csvArray.indexOf('Commentaire(s) interne') + 1, 0, 'id');
    csvArray.splice(csvArray.indexOf('Spécificités') + 1, 0, 'Spécificités interne');
    csv = csvArray.join(';');
  }

  customers.forEach(customer => {
    let zone = '';
    let sav = '';
    const pickup = { period: { start: { date: '', hour: '' }, end: { date: '', hour: '' } } };
    const delivery = { period: { start: { date: '', hour: '' }, end: { date: '', hour: '' } } };
    if (customer.pickup && customer.pickup.date) {
      pickup.period.start.date = moment(customer.pickup.period.start.date).format('DD/MM/YYYY');
      pickup.period.start.hour = customer.pickup.period.start.hour;
    }

    if (customer.delivery && customer.delivery.date) {
      delivery.period.start.date = moment(customer.delivery.period.start.date).format('DD/MM/YYYY');
      delivery.period.start.hour = customer.delivery.period.start.hour;
    }

    if (customer.sav) {
      const findSav = savs.find(sv => sv._id.toString() === customer.sav.toString());
      if (findSav) {
        sav = findSav.name;
      }
    }

    if (customer.zone) {
      const findZone = zones.find(zn => zn.name.toString() === customer.zone.toString());
      if (findZone) {
        zone = findZone.name;
      }
    }

    const status = mapStatus(customer.status);
    csv += `${customer.firstname || '-'};${customer.name || '-'};${customer.tel || '-'};${customer.email || '-'};`;
    csv += `${clean(customer.address) || '-'};${customer.zipCode || '-'};${customer.city || '-'};${customer.country || '-'};${clean(customer.additionalInformation) || '-'};`;
    csv += `${clean(customer.nameOnSite) || '-'};${customer.telOnSite || '-'};${clean((customer.specificities || []).join(' - ')) || '-'};`;
    if (role === 'admin') {
      csv += `${clean((customer.chefingSpecificities || []).join(' - ')) || '-'};`;
    }
    csv += `${(customer.warning ? customer.warning : 'Aucune alerte') + ' (' + (customer.commentOnWarning ? customer.commentOnWarning : 'Aucun commentaire sur l\'alerte') + ')'};${pickup.period.start.date};${pickup.period.end.date};${pickup.period.start.hour} - ${pickup.period.end.hour};${delivery.period.start.date};${delivery.period.end.date};${delivery.period.start.hour} - ${delivery.period.end.hour};${status};`;
    csv += `${customer.carrier || '-'};`;
    csv += `${event.referenceNumber || '-'};${clean(event.customer?.company) || '-'};${sav || '-'};`;
    if (role === 'admin') {
      csv += `${zone || '-'};`;
    }
    csv += `${clean(customer.comment) || '-'};${clean(customer.chefingComments?.join(' - ')) || '-'};`;
    if (role === 'admin') {
      csv += `${customer?.externalId || '-'};`;
    }
    csv += `${customer.addressStatus || '-'}`;
    // csv += role === 'admin' ? `;${zone || '-'};${clean((customer.chefingSpecificities || []).join(' - ')) || '-'};${customer?.externalId || '-'}\n` : '\n';
    csv += '\n';
  });
  return csv;
};

/** Clean special chars from string */
const clean = (str: string) => str?.replace(/[&\/\\#,+()$~%":*?<>{}]/g, '').replace(/[\n\r]/g, ' ') || '';


export const mapStatus = (status: string): string => {
  switch (status) {
    case 'pending':
      return 'En attente de programmation';
    case 'scheduled':
      return 'Programmé';
    case 'on_progress':
      return 'En cours';
    case 'delivered':
      return 'Réceptionnée';
    case 'not_delivered':
      return 'Non-réceptionnée';
    case 'parcel_relay':
      return 'Relais colis';
    default:
      return 'Programmée';
  }
};

/**
 * Create hidden document element fill it with data & download it
 * @param extension - csv / txt / json
 * @param filename - downloaded filname
 * @param content - content to download
 */
export const createAndDownloadFile = (filename: string, extension: string, content: string): void => {
  const link = window.document.createElement('a');
  link.setAttribute('href', `data:text/${extension};charset=utf-8,%EF%BB%BF${encodeURIComponent(latinize(content))}`);
  link.setAttribute('download', `${filename}.${extension}`);
  link.setAttribute('target', '_blank');
  link.click();
};

/**
 * Replace all accent letter by non accent one
 */
const latinize = (str: string) => str
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '');


export const mainChefingAppearanceColors = {
  dark: 'var(--dark)',
  blue: 'var(--dark-green)',
  yellow: 'var(--dark-yellow)',
  green: 'var(--dark-green)',
  pink: 'var(--dark-pink)',
  lightPink: 'var(--pink)',
  white: 'var(--white)',
  grey: 'var(--grey)',
};

export const COLOR_PICKER_PALETTE = [
  '#000105', '#3e6158', '#3f7a89', '#96c582', '#b7d5c4', '#bcd6e7', '#7c90c1', '#9d8594',
  '#dad0d8', '#4b4fce', '#4e0a77', '#a367b5', '#ee3e6d', '#d63d62', '#c6a670', '#f46600',
  '#cf0500', '#efabbd', '#8e0622', '#f0b89a', '#f0ca68', '#62382f', '#c97545', '#c1800b'
];
