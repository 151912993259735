<div class="contacts-details" [formGroup]="form">
    <!-- HEADER -->
    <div class="header">
        <div class="icon-holder">
            <div class="white-circle">
                <mat-icon class="mat-icon-person">person</mat-icon>
            </div>
        </div>
        <div style="display: flex; flex-direction: column">
            <input class="name" formControlName="firstname" matInput required>
            <input class="name" formControlName="lastname" matInput required>
        </div>
    </div>
    <!-- BODY -->
    <div class="body">
        <div class="form-info">
            <mat-label class="info">Téléphone :</mat-label>
            <mat-form-field style="margin-top: 10px;">
                <mat-label class="info">Téléphone</mat-label>
                <input formControlName="tel" matInput required>
            </mat-form-field>
        </div>
        <div class="form-info">
            <mat-label class="info">Email :</mat-label>
            <mat-form-field style="margin-top: 10px;">
                <mat-label class="info">Email :</mat-label>
                <input formControlName="email" matInput>
            </mat-form-field>
        </div>
        <div class="form-info">
            <mat-label class="info">Entreprise :</mat-label>
            <mat-form-field style="margin-top: 10px;">
                <mat-label class="info">Entreprise :</mat-label>
                <input formControlName="company" matInput required>
            </mat-form-field>
        </div>
    </div>
    <div class="flex-column" style="margin-bottom: 15px;">
        <div class="button-container">
            <button mat-button [disabled]="!contact || !form.valid || isFormDisabled" class="button-enabled" (click)="updateContact();"
                [ngClass]="{'button-disabled': !contact || !form.valid || isFormDisabled, 'button-enabled': contact && form.valid && !isFormDisabled}">
                <span style="color: var(--white);">Valider</span>
            </button>
        </div>
    </div>
</div>
