<!-- First Toolbar -->
<mat-toolbar color="primary" style="height: 45px;">
  <img *ngIf="user?.role === 'admin'" src="/assets/charlie.png" alt="Logo d'Aclhimii" style="stroke: white" width="60px">
  <img *ngIf="!user || user?.role !== 'admin'" [src]="alchimiiLogoPath" alt="Logo d'Aclhimii" width="80px" height="45px">
  <div *ngFor="let menu of menus" class="flex-row h-full">
    <button *ngIf="user" mat-menu-item class="flex-row" (click)="navigate(menu.link)">
      <mat-icon style="color:var(--white); font-size: 20px;">{{menu.icon}}</mat-icon>
      <span style="color:var(--white);">{{menu.value}}</span>
    </button>
  </div>
  <span class="example-spacer"></span>
  <!-- Profil Menu -->
  <div *ngIf="user" class="flex-row-align-center">
    <span>{{user.firstname}} {{user.lastname}}</span>
    <app-menu [iconMenu]="'account_circle'" [menus]="profilMenu" [currentPage]="'navbar'" (menuClickedE)="menuClicked($event)"></app-menu>
  </div>
  <!-- Setting Menu -->
  <div *ngIf="user?.role === 'admin'" class="flex-row-align-center">
    <app-menu [iconMenu]="'settings'" [menus]="settingMenu" [currentPage]="'navbar'" (menuClickedE)="menuClicked($event)"></app-menu>
  </div>
</mat-toolbar>

<!-- Second Toolbar -->
<mat-toolbar *ngIf="user && !isPublic" style="height: 40px;">
  <button *ngIf="subMenus" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <app-event-info *ngIf="eventInfo" [eventInfo]="eventInfo" [role]="user.role" (openMoreEventInformationE)="openMoreEventInformation($event)"></app-event-info>
  <mat-chip-list *ngIf="activatedFilters" class="chip-list">
    <mat-chip *ngFor="let chip of activatedFilters;" selected>
      <mat-icon class="add-button-icon">{{chip.icon}}</mat-icon>
      {{chip.displayValue}}
      <mat-icon matChipRemove (click)="removeActiveFilters(chip)">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</mat-toolbar>

<!-- 1er niveau -->
<mat-menu #menu="matMenu">
  <div *ngFor="let menu of subMenus">
    <button mat-menu-item *ngIf="!menu.children" matTooltip="{{menu.value}}" (click)="navigate(menu.link)">
      <mat-icon>{{menu.icon}}</mat-icon>
      <span class="truncate-text">{{menu.value}}</span>
    </button>
    <button mat-menu-item *ngIf="menu.children" matTooltip="{{menu.value}}" [matMenuTriggerFor]="childrenMenu" (click)="navigate(menu.link)">
      <mat-icon>{{menu.icon}}</mat-icon>
      <span class="truncate-text">{{menu.value}}</span>
    </button>
    <!-- 2ème niveau -->
    <mat-menu #childrenMenu="matMenu">
      <div *ngFor="let child of menu.children">
        <button mat-menu-item *ngIf="!child.children" matTooltip="{{child.value}}" (click)="navigate(menu.link, child.link)">
          <mat-icon>{{child.icon}}</mat-icon>
          <span class="truncate-text">{{child.value}}</span>
        </button>
        <button mat-menu-item *ngIf="child.children" matTooltip="{{child.value}}" (click)="navigate(menu.link, child.link)">
          <mat-icon>{{child.icon}}</mat-icon>
          <span class="truncate-text">{{child.value}}</span>
        </button>
      </div>
    </mat-menu>
  </div>
</mat-menu>
