<section class="component-holder">
    
 <!--    <app-header
      [title]="'Mon repértoire'" [whichComponent]="'repertory'" 
      [isDeleteButtonDisabled]="isDeleteButtonDisabled" [isIntoRepertory]="true"
      [isRepertoryShared]="limitDate.length" [repertoryLimitDate]="repertoryLimitDate"
      [isRepertoryLimitDateDisplayed]="isRepertoryLimitDateDisplayed"
      [menuOrder]="menuOrder" (sendQueriesE)="onSearch($event)"
      (openAddContactToRepertoryDialogE)="openAddContactToRepertoryDialog();"
      (openDeleteContactToRepertoryDialogE)="openDeleteContactToRepertoryDialog();"
      (openShareRepertoryAccessDialogE)="openShareRepertoryAccessDialog()"
      (importContactsToRepertoryE)="openImportContactsToRepertoryDialog()">
    </app-header> -->

    <div class="flex-row-100">
        <div class="contact-board">
            <app-contact-board *ngFor="let contacts of dataSource; trackBy: trackByContacts" 
                [contacts]="contacts" (setCurrentContactE)="setCurrentContact($event)"
                (contactHasBeenCheckedE)="contactHasBeenChecked($event)"
                (findContactsE)="findContacts($event);" [isLoading]="isLoading">
            </app-contact-board>
        </div>
        <div class="contact-details">
            <app-contact-details *ngIf="!isContactDetailsDisabled" [contact]="currentContact"
                (updateContactE)="updateContact($event)">
            </app-contact-details>
        </div>
    </div>
  
</section>
