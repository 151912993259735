import { MenusControls } from '@core/models/left-menu.model';

export const menuses: MenusControls = {
  organisateur: [
    {
      icon: 'home',
      link: 'client/evenements',
      value: 'Événements',
      subChildName: 'evenements',
      children: [
        {
          icon: 'show_chart',
          link: 'evenements/{id}/livraisons',
          value: 'Statut livraison',
        },
        {
          icon: 'dashboard',
          link: 'evenements/{id}/tableau-de-bord',
          value: 'Tableau de bord'
        },
        {
          icon: 'assignment',
          link: 'evenements/{id}/document',
          value: 'Gestion des documents'
        }
      ]
    },
    {
      icon: 'menu_book',
      link: 'repertoire',
      value: 'Répertoire'
    }
  ],
  admin: [
    {
      icon: 'home',
      link: 'admin/evenements',
      value: 'Événements',
      subChildName: 'evenements',
      children: [
        {
          icon: 'rule',
          link: 'evenements/{id}/dispatch',
          value: 'Produits'
        },
        {
          icon: 'location_on',
          link: 'evenements/{id}/adresses',
          value: 'Adresses',
          subChildName: 'adresses',
          children: [
            {
              icon: 'build',
              link: 'bundle',
              value: 'Gestion des adresses'
            },
            {
              icon: 'join_inner',
              link: 'associate-bundle',
              value: 'Plannification des adresses'
            },
            {
              icon: 'checklist',
              link: 'statut-livraisons',
              value: 'Statut livraison',
            }
          ]
        },
        {
          icon: 'pending_actions',
          link: 'evenements/{id}/communication',
          value: 'Communication'
        },
        {
          icon: 'auto_graph',
          link: 'evenements/{id}/infographie',
          value: 'Infographie'
        }
      ]
    },
    {
      icon: 'local_shipping',
      link: 'delivery-management',
      value: 'Gestion de livraison',
      subChildName: 'delivery-management',
      children: [
        {
          icon: 'local_shipping',
          link: 'delivery-management/enlevements',
          value: 'Gestion des enlèvements'
        },
        {
          icon: 'local_shipping',
          link: 'delivery-management/livraisons',
          value: 'Gestion des livraisons'
        }
      ]
    },
    {
      icon: 'auto_stories',
      link: 'repertories',
      value: 'Répertoires',
      subChildName: 'repertory',
      children: [
        {
          icon: 'signpost',
          link: 'repertories/addresses',
          value: 'Répertoire d\'adresses'
        }
      ]
    }
  ]
};
