import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from '@shared/material.module';
import { SharedModule } from '@shared/shared.module';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthService } from './services/auth.service';

import { NavbarComponent } from './components/navbar/navbar.component';
import { ShellComponent } from './components/shell/shell.component';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { DialogGenerateEocComponent } from './dialogs/dialog-generate-eoc/dialog-generate-eoc.component';
import { EventInfoComponent } from './components/event-info/event-info.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ShellComponent,
    DialogGenerateEocComponent,
    EventInfoComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    AppRoutingModule
  ],
  exports: [
    NavbarComponent,
  ],
  providers: [
    AuthService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
  ]
})
export class CoreModule {
}
