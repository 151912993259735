import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ShellComponent } from '@core/components/shell/shell.component';
import { AuthGuard } from '@core/guard/auth.guard';
import { LogoutComponent } from '@shared/components/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: 'authentification',
        data: {},
        loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'convive',
        data: {},
        loadChildren: () => import('./features/guest/guest.module').then((m) => m.GuestModule)
      },
      {
        path: 'client',
        canActivate: [AuthGuard],
        data: {},
        loadChildren: () => import('./features/client/client.module').then((m) => m.ClientModule)
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {},
        loadChildren: () => import('./features/ops/ops.module').then((m) => m.OpsModule)
      },
      {
        path: 'public',
        data: {},
        loadChildren: () => import('./features/public/public.module').then((m) => m.PublicModule)
      },
      {
        path: 'deconnexion',
        component: LogoutComponent
      },
      {
        path: 'chefing',
        redirectTo: 'admin'
      },
      {
        path: '**',
        redirectTo: 'authentification'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
