<section class="holder">
  <app-navbar
    class="navbar-style"
    *ngIf="!isLoginPage"
    [ngStyle]="{'height': isPublic ? '45px' : '85px'}"
    [menus]="menus" [subMenus]="subMenus"
    [user]="user" [eventInfo]="eventInfo"
    (logoutE)="logout()" (navigateE)="navigate($event)"
    (openMoreEventInformationE)="openMoreEventInformation($event)">
  </app-navbar>
  <router-outlet class="router-outlet-style"></router-outlet>
</section>

