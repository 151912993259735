import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface Data {
  event: {
    contacts: any[]
    customer: {
      company: string,
      firstname: string,
      lastname: string,
      email: string,
      tel: string
    },
    customerAddress: string,
    deliveryAddress: string,
    name: string
    period: {
      start: {
        date: string,
        hour: string
      },
      end: {
        date: string,
        hour: string
      }
    },
    referenceNumber: string,
    users: {
      gAgendaEvents: { eventId: string, momentId: string }[]
      kind: string,
      value: any // (string | User)
    }[],
    _id: string
  };
  role: string;
  users: User[];
}

interface User {
  email: string;
  firstname: string;
  kinds: string[];
  lastname: string;
  role: string;
  roles: string[];
  tel: string;
  updatedAt: string;
  _id: string;
}

@Component({
  selector: 'app-event-detail-dialog',
  templateUrl: './event-detail-dialog.component.html',
  styleUrls: ['./event-detail-dialog.component.scss']
})
export class EventDetailDialogComponent implements OnInit, AfterViewInit {
  eventGroup: FormGroup;
  disableAnimation = true;

  constructor(public dialogRef: MatDialogRef<EventDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Data, private fb: FormBuilder) {
    this.eventGroup = this.fb.group({
      firstname: [''],
      lastname: [''],
      email: [''],
      tel: [''],
      company: [''],
      name: [''],
      referenceNumber: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startHour: [''],
      endHour: [''],
      userOperation: [''],
      userLogistic: [''],
      userVerification: [''],
      currentContact: [''],
      contacts: [''],
      customerAddress: [''],
      deliveryAddress: [''],
      telRespoOps: ''
    });
  }

  ngOnInit(): void {
    if (this.data.event) {
      const customer = this.data.event.customer;
      const period = this.data.event.period;
      let userOperation;
      let userLogistic;
      let userVerification;
      let telRespoOps = '';
      if (this.data.event.users && this.data.event.users.length > 0) {
        this.data.event.users
          .filter(user => user && user.kind && user.value)
          .forEach(user => {
            if (user.kind === 'operation') {
              if (this.data.role !== 'admin') {
                userOperation = user.value._id;
                telRespoOps = user.value.tel ? user.value.tel : '';
              } else {
                userOperation = user.value;
                const tel = this.data.users.find(u => u._id === user.value).tel;
                if (tel) {
                  telRespoOps = tel;
                }
              }
            }
            if (user.kind === 'logistic') {
              if (this.data.role !== 'admin') {
                userLogistic = user.value._id;
                telRespoOps = user.value.tel ? user.value.tel : '';
              } else {
                userLogistic = user.value;
                const tel = this.data.users.find(u => u._id === user.value).tel;
                if (tel) {
                  telRespoOps = tel;
                }
              }
            }
            if (user.kind === 'verification') {
              if (this.data.role !== 'admin') {
                userVerification = user.value._id;
                telRespoOps = user.value.tel ? user.value.tel : '';
              } else {
                userVerification = user.value;
                const tel = this.data.users.find(u => u._id === user.value).tel;
                if (tel) {
                  telRespoOps = tel;
                }
              }
            }
          })
      }
      this.eventGroup.patchValue({
        firstname: customer.firstname || '',
        lastname: customer.lastname || '',
        email: customer.email || '',
        tel: customer.tel || '',
        company: customer.company || '',
        name: this.data.event.name || '',
        referenceNumber: this.data.event.referenceNumber || '',
        startDate: period.start && period.start.date ? period.start.date : '',
        endDate: period.end && period.end.date ? period.end.date : '',
        startHour: period.start && period.start.hour ? period.start.hour : '',
        endHour: period.end && period.end.hour ? period.end.hour : '',
        userOperation: userOperation,
        userLogistic: userLogistic,
        userVerification: userVerification,
        contacts: this.data.event.contacts || [],
        // customerAddress: this.data.event.customerAddress || '',
        // deliveryAddress: this.data.event.deliveryAddress || '',
        telRespoOps: telRespoOps
      });
    }
    if (this.data.role !== 'admin') {
      this.eventGroup.disable();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false); // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
  }

  addContact(): void {
    this.eventGroup.value.contacts.push(this.eventGroup.value.currentContact);
    this.eventGroup.get('currentContact').setValue('');
  }

  removeContact(index): void {
    this.eventGroup.value.contacts.splice(index, 1);
  }
}
