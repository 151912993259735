<section class="flex-column-100">
    <div class="mat-expansion-panel-holder">
        <mat-icon class="animation" style="color: var(--dark-green); left: -3%;">double_arrow</mat-icon>
        <mat-expansion-panel (click)="findContacts(contacts.letter);"
            (opened)="panelOpenState = true" (closed)="panelOpenState = false" [@.disabled]="disableAnimation" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="letter-style">{{contacts.letter}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
        </mat-expansion-panel>

        <div class="horizontal-line"></div>
    </div>

    <!-- Contact List -->
    <ng-container *ngIf="panelOpenState && !isLoading && contacts.datas">
        <div class="after" class="flex-column-100">
            <div *ngFor="let contact of contacts.datas" class="addresses-wrapper" (click)="setCurrentContact(contact);">
                <div class="icon-wrapper">
                    <mat-icon class="person-icon-style">person</mat-icon>
                </div>
                <span class="row-cell" style="width: 22.5%" [matTooltip]="contact.lastname || ''">{{contact.lastname || '' | ellipsis:10}}</span>
                <span class="row-cell" style="width: 22.5%" [matTooltip]="contact.firstname || ''">{{contact.firstname || '' | ellipsis:10}}</span>
                <span class="row-cell" style="width: 22.5%" [matTooltip]="contact.company || ''">{{contact.company || '' | ellipsis:10}}</span>
                <mat-checkbox class="row-cell" style="width: 22.5%" (change)="contactHasBeenChecked($event, contact);"></mat-checkbox>
            </div>
        </div>
    </ng-container>

    <!-- No data to display -->
    <ng-container *ngIf="panelOpenState && !isLoading && !contacts.datas.length">
        <div class="after" class="flex-column-100">
            <div class="no-data-wrapper">
                <mat-icon style="color: var(--dark); margin-right: 5px;">person</mat-icon>
                <span>Aucun contact</span>
            </div>
        </div>
    </ng-container>

    <!-- Loader -->
    <ng-container *ngIf="panelOpenState && isLoading">
        <div style="display: flex;flex-direction: row;align-items: center;align-self: flex-end;justify-content: center;width: 90%;">
            <mat-spinner diameter="50" class="simple-loader"></mat-spinner>
        </div>
    </ng-container>
</section>

