<mat-form-field [appearance]="appearance" [formGroup]="form">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList [disabled]="form.get(controlName).disabled">
    <mat-chip (removed)="remove(v, controlName)" *ngFor="let v of values(controlName)" [removable]="true">
      {{ v }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #chipEntry
      (matChipInputTokenEnd)="add($event, controlName)"
      [formArrayName]="controlName"
      [matChipInputFor]="chipList"
      aria-label=""
      placeholder="Appuyer sur entrer pour valider la saisie"
    >
  </mat-chip-list>
  <button [disabled]="form.get(controlName).disabled" (click)="add(chipEntry, controlName)" color="primary" mat-icon-button matSuffix>
    <mat-icon>add_circle_outline</mat-icon>
  </button>
</mat-form-field>
