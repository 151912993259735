import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss']
})
export class EventInfoComponent implements OnInit {

  @Input() eventInfo: any;
  @Input() role: string;
  @Output() openMoreEventInformationE: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  openMoreEventInformation($event) {
    this.openMoreEventInformationE.emit($event);
  }

}
