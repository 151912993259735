import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Contact, RepertoryDataSource } from '@features/repertory/models/repertory-view.model';

@Component({
  selector: 'app-contact-board',
  templateUrl: './contact-board.component.html',
  styleUrls: ['./contact-board.component.scss']
})
export class ContactBoardComponent implements OnInit {

  @Input() contacts: RepertoryDataSource;
  @Input() isLoading: boolean;
  @Output() setCurrentContactE: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output() findContactsE: EventEmitter<string> = new EventEmitter<string>();
  @Output() contactHasBeenCheckedE: EventEmitter<{checked: boolean, contact: Contact}> = new EventEmitter<{checked: boolean, contact: Contact}>();
  panelOpenState: boolean = false;
  disableAnimation: boolean = true;
  contactIds: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false); // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
  }

  setCurrentContact(contact: Contact) {
    this.setCurrentContactE.emit(contact);
  }

  findContacts(letter: string) {
    this.findContactsE.emit(letter);
  }

  contactHasBeenChecked($event: MatCheckboxChange, contact: Contact) {
    this.contactHasBeenCheckedE.emit({checked: $event.checked, contact: contact});
  }

}
