import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackService } from '@core/services/snack.service';
import { SearchParams } from '@shared/models/search-params.model';
import { computeQuery } from '@shared/utils/query.utils';
import { Subject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact, RepertoryDataSource } from '../models/repertory-view.model';

@Injectable({
  providedIn: 'root'
})
export class RepertoryService {

  private repertory$: Subject<any> = new Subject<any>();
  private apiBase: string = environment.baseUrl;
  private repertoryRoute: string = `${this.apiBase}/repertory`;

  constructor(
    private http: HttpClient,
    private snack: SnackService
  ) {
  }

  public subscribeToRepertory(): Observable<any> {
    return this.repertory$.asObservable();
  }

  find(urlQuery: SearchParams): Subscription {
    const url = `${this.repertoryRoute}?${computeQuery(urlQuery)}`;
    return this.http.get<RepertoryDataSource[]>(url)
      .subscribe((data) => this.repertory$.next(data));
  }

  findByPromoterId(promoterId: string, urlQuery: SearchParams): Observable<any> {
    const url = `${this.repertoryRoute}/customer/${promoterId}?${computeQuery(urlQuery)}`;
    return this.http.get<any>(url);
  }

  getLimitDate(): Observable<any> {
    const url = `${this.repertoryRoute}`;
    return this.http.get<any>(url);
  }

  setLimitDate(limitDate: string): Observable<any> {
    const url = `${this.repertoryRoute}`;
    return this.http.patch<any>(url, {limitDate});
  }

  findById(id: string): Observable<any> {
    const url = `${this.repertoryRoute}/${id}`;
    return this.http.get<Contact>(url);
  }

  createContact(body: any, urlQuery: SearchParams): Subscription {
    const url = `${this.repertoryRoute}?${computeQuery(urlQuery)}`;
    return this.http.post(url, body)
      .subscribe((data: any) => {
          this.repertory$.next(data);
          this.snack.open('success', 'Crée avec succès');
        }
      );
  }

  /** Same as above without subscription */
  createContactObs(body: any, urlQuery: SearchParams): Observable<any> {
    const url = `${this.repertoryRoute}?${computeQuery(urlQuery)}`;
    return this.http.post(url, body);
  }

  updateById(_id: string, body: Contact, urlQuery: SearchParams): Subscription {
    const url = `${this.repertoryRoute}/${_id}?${computeQuery(urlQuery)}`;
    return this.http.patch(url, body)
      .subscribe((data: any) => {
        this.repertory$.next(data);
        this.snack.open('success', 'Mis à jour avec succès');
      });
  }

  deleteContact(contactIds: string[], urlQuery: SearchParams): Subscription {
    const url = `${this.repertoryRoute}/remove/contact/?${computeQuery(urlQuery)}`;
    return this.http.post<any>(url, {contactIds})
      .subscribe((data) => {
        this.repertory$.next(data);
        this.snack.open('success', 'Supprimé avec succès');
      });
  }

  addRepertoryToCustomer(body: {contactIds: string[], action: string}, eventId: string): Observable<any> {
    const url = `${this.repertoryRoute}/convert/customer`;
    return this.http.post(url, {...body, eventId});
  }

  importCsv(body: {csv: string[]}, urlQuery: SearchParams): Subscription {
    const url = `${this.repertoryRoute}/csv/import/?${computeQuery(urlQuery)}`;
    return this.http.post(url, body)
      .subscribe(
        (data: any) => {
          this.repertory$.next(data);
          this.snack.open('success', 'Contact(s) importé(s) avec succès !');
        },
        (err) => {
          this.snack.open('success', 'Une erreur est survenue lors de l\'import de vos contacts');
        }
      );
  }
}

