<ng-container *ngIf="emitValues; then emitter else noEmit"></ng-container>

<ng-template #emitter>
  <mat-form-field [appearance]="appearance" (change)="onChange.emit($event)">
    <mat-label>{{ label }}</mat-label>
    <input matInput [type]="type" [required]="required" [disabled]="disabled" [(ngModel)]="model" aria-label="">
  </mat-form-field>
</ng-template>

<ng-template #noEmit>
  <mat-form-field [appearance]="appearance" [formGroup]="form">
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControlName]="controlName" [type]="type" [disabled]="disabled" [required]="required" aria-label="">
  </mat-form-field>
</ng-template>
