import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authS: AuthService, private router: Router) {
    this.authS.logout();
    localStorage.removeItem('eoc-user');
    this.router.navigate(['/authentication/client']);
  }

  ngOnInit(): void {
  }

}
