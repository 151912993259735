import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { computeQuery } from '@shared/utils/query.utils';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { SnackService } from '@core/services/snack.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private apiBase: string = environment.baseUrl;
  private eventEndpoint: string = `${this.apiBase}/event`;
  private externalEndpoint: string = `${this.apiBase}/external`;

  private eventData$: Subject<any> = new Subject<any>();
  private currentEvent: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private dashboardData$: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private snack: SnackService
  ) {}

  public subscribeToEventData() {
    return this.eventData$.asObservable();
  }

  public subscribeToDashboardData() {
    return this.dashboardData$.asObservable();
  }

  public get currentEventValue() {
    return this.currentEvent.value;
  }

  /**
   * Get Event List
   * @param urlQuery
   * @returns
   */
  public getEventList(urlQuery: any): Subscription {
    const url = `${this.eventEndpoint}?${computeQuery(urlQuery)}`;
    return this.http.get(url).subscribe(
      (res) => this.eventData$.next(res)
    );
  }

  public openSignApp(id: string): Observable<any> {
    const url = `${this.eventEndpoint}/generateSignApp/${id}`;
    return this.http.get(url);
  }

  /**
   * Count Event
   * @param userId
   * @returns
   */
  public getCountEvent(urlQuery): Observable<any> {
    const url = `${this.eventEndpoint}/count?${computeQuery(urlQuery)}`;
    return this.http.get(url);
  }

  /**
   * Generate participant gift link
   * @param id - eventId
   */
  generateGiftLink(id: string): Observable<{ url: string}> {
    const url = `${this.eventEndpoint}/${id}/generateGiftLink`;
    return this.http.get<{ url: string}>(url);
  }

  /**
   * Récupération des informations de l'organisateur de l'événement
   * @param id
   * @returns An Observable
   */
  public findById(id: string): Observable<any> {
    const url = `${this.eventEndpoint}/${id}`;
    return this.http.get(url).pipe(
      map(event => {
        this.currentEvent.next(event);
        return event;
      })
    );
  }

  /**
   * Update General Informations
   * @param id
   * @param payload
   * @returns An Observable
   */
  updateGeneralInformations(id, payload): Observable<any> {
    const url = `${this.eventEndpoint}/${id}`;
    return this.http.patch(url, payload);
  }

  /**
   * get data for communication view
   * @param id - eventId
   * @returns An Observable
   */
  getCommunicationInformation(id: string): Observable<any> {
    const url = `${this.eventEndpoint}/${id}/dispatch`;
    return this.http.get(url);
  }

  /**
   * get data for dashboard view (on promoter interface)
   * @param id - eventId
   * @param query
   * @returns An Observable
   */
  getDashboard(id: string, query: string): Subscription {
    const url = `${this.eventEndpoint}/${id}/dashboard${query}`;
    return this.http.get(url).subscribe(
      (res) => this.dashboardData$.next(res),
      (error) => console.log(error)
    );
  }

  generatePromoter(eventId: string, payload): Observable<any> {
    const url = `${this.eventEndpoint}/generateEoc/${eventId}`;
    return this.http.post(url, payload);
  }

  notifyEocPromoter(eventId: string, payload): Observable<any> {
    const url = `${this.eventEndpoint}/notifyEoc/${eventId}`;
    return this.http.post(url, payload);
  }


  /**
   * Création d'un scheduler
   * @param message - Message à envoyer
   * @param date - Date sms à envoyer
   * @param time - Heure sms à envoyer
   * @returns An Observable
   */
  createScheduler(id: string, payload: any): Observable<any> {
    const url = `${this.eventEndpoint}/${id}/sms/planning`;
    return this.http.post(url, payload);
  }

}
