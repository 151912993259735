import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from '@core/services/snack.service';

@Component({
  selector: 'app-add-contact-to-repertory-dialog',
  templateUrl: './add-contact-to-repertory-dialog.component.html',
  styleUrls: ['./add-contact-to-repertory-dialog.component.scss']
})
export class AddContactToRepertoryDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private snack: SnackService,
    private dialogRef: MatDialogRef<AddContactToRepertoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      lastname: this.fb.control('', Validators.required),
      firstname: this.fb.control('', Validators.required),
      email: this.fb.control(''),
      tel: this.fb.control('', Validators.required),
      company: this.fb.control('', Validators.required)
    });
  }

  ngOnInit(): void {}

  createContact() {
    if (this.form.invalid) {
      this.snack.open('error', 'Veuillez remplir tous les champs obligatoires')
    } else {
      this.dialogRef.close(this.form.value);
    }
  }

}
