import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SharedService } from '@core/services/shared.service';

interface Menu {
  icon: string;
  label: string;
  value: string;
  color: string;
  page: string;
  disabled: boolean;
}


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() iconMenu: string;
  @Input() menus: Menu[];
  @Input() currentPage: string;
  @Output() menuClickedE: EventEmitter<Menu> = new EventEmitter<Menu>();
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;

  constructor(
    private sharedService: SharedService
  ) {
    this.sharedService.menuEmitter.subscribe(this.toggleMenu.bind(this));
  }

  ngOnInit() {
  }

  toggleMenu(state: boolean): void {
    this.matMenuTrigger.openMenu();
  }

  menuClicked(menu: Menu) {
    this.menuClickedE.emit(menu);
  }

}
