import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SelectMultipleComponent } from "@shared/components/form/select-multiple/select-multiple.component";
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeliveryBlockComponent } from '@shared/components/delivery-block/delivery-block.component';
import { DragAndDropCardComponent } from '@shared/components/drag-and-drop-card/drag-and-drop-card.component';
import { InputComponent } from '@shared/components/mat-form/input/input.component';
import { AddressActionsComponent } from '@shared/components/header-components/address-actions/address-actions.component';
import { AddressResumeInformationsComponent } from '@shared/components/header-components/address-resume-informations/address-resume-informations.component';
import { DateFilterComponent } from '@shared/components/header-components/date-filter/date-filter.component';
import { DeliveryWarningComponent } from '@shared/components/header-components/delivery-warning/delivery-warning.component';
import { EventsCounterComponent } from '@shared/components/header-components/events-counter/events-counter.component';
import { HeaderTitleComponent } from '@shared/components/header-components/header-title/header-title.component';
import { ProfilPictureComponent } from '@shared/components/header-components/profil-picture/profil-picture.component';
import { RangeDateFilterComponent } from '@shared/components/header-components/range-date-filter/range-date-filter.component';
import { SavFilterComponent } from '@shared/components/header-components/sav-filter/sav-filter.component';
import { SavPricesComponent } from '@shared/components/header-components/sav-prices/sav-prices.component';
import { SearchBarComponent } from '@shared/components/header-components/search-bar/search-bar.component';
import { ZoneFilterComponent } from '@shared/components/header-components/zone-filter/zone-filter.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { HomeClientComponent } from '@shared/components/home-client/home-client.component';
import { InputHandlerComponent } from '@shared/components/input-handler/input-handler.component';
import { MediaFooterComponent } from '@shared/components/media-footer/media-footer.component';
import { ProfilComponent } from '@shared/components/profil/profil.component';
import { ProgessLoaderComponent } from '@shared/components/progess-loader/progess-loader.component';
import { SelectMomentComponent } from '@shared/components/select-moment/select-moment.component';
import { CarrierModelCsvSelectorComponent } from '@shared/components/selector-components/carrier-model-csv-selector/carrier-model-csv-selector.component';
import { CarrierSelectorComponent } from '@shared/components/selector-components/carrier-selector/carrier-selector.component';
import { ChefingCommentSelectorComponent } from '@shared/components/selector-components/chefing-comment-selector/chefing-comment-selector.component';
import { SnackErrorComponent, SnackInfoComponent, SnackLoadingComponent, SnackSuccessComponent, SnackWarningComponent } from '@shared/components/snacks';
import { TableListComponent } from '@shared/components/table-list/table-list.component';
import { TypologyComponent } from '@shared/components/typology/typology.component';
import { HistoryCollapsePanelComponent } from './components/history-collapse-panel/history-collapse-panel.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { ParenthesesPipe } from './pipes/parentheses.pipe';
import { DeliveryExpansionTableComponent } from './components/delivery-expansion-table/delivery-expansion-table.component';
import { CategoryPlanningDetailsComponent } from './components/shared-planning-components/components/category-planning-details/category-planning-details.component';
import { ProgressLoaderDialogComponent } from './dialogs/progress-loader-dialog/progress-loader-dialog.component';
import { ChefingSelectorComponent } from './components/selector-components/chefing-selector/chefing-selector.component';
import { DeliveriesFilterDialogComponent } from '@features/ops/dialogs/deliveries-filter-dialog/deliveries-filter-dialog.component';
import { GeneralInformationsBlockComponent } from './components/shared-planning-components/components/general-informations-block/general-informations-block.component';
import { ItemPlanningDetailsComponent } from './components/shared-planning-components/components/item-planning-details/item-planning-details.component';
import { MomentPlanningDetailsComponent } from './components/shared-planning-components/components/moment-planning-details/moment-planning-details.component';
import { SharedPlanningComponent } from './components/shared-planning-components/components/shared-planning/shared-planning.component';
import { SubCategoryPlanningDetailsComponent } from './components/shared-planning-components/components/sub-category-planning-details/sub-category-planning-details.component';
import { ItemPlanningDetailsDialogComponent } from './components/shared-planning-components/dialogs/item-planning-details-dialog/item-planning-details-dialog.component';
import { HourFilterComponent } from './components/header-components/hour-filter/hour-filter.component';
import { InputAutocompleteComponent } from './components/mat-form/input-autocomplete/input-autocomplete.component';
import { DateInputComponent } from './components/mat-form/date-input/date-input.component';
import { ChipsInputComponent } from './components/mat-form/chips-input/chips-input.component';
import { ChipsSelectorComponent } from './components/selector-components/chips-selector/chips-selector.component';
import { InputDialogComponent } from './dialogs/input-dialog/input-dialog.component';
import { GlobalSearchBarComponent } from './components/basic-components/global-search-bar/global-search-bar.component';
import { ChipListComponent } from './components/mat-form/chip-list/chip-list.component';
import { ChipComponent } from './components/mat-form/chip/chip.component';
import { CloseButtonComponent } from './components/mat-form/close-button/close-button.component';
import { DateRangeComponent } from './components/mat-form/date-range/date-range.component';
import { DividerComponent } from './components/mat-form/divider/divider.component';
import { IconComponent } from './components/mat-form/icon/icon.component';
import { IconButtonComponent } from './components/mat-form/icon-button/icon-button.component';
import { MenuComponent } from './components/mat-form/menu/menu.component';
import { ToggleComponent } from './components/mat-form/toggle/toggle.component';
import { WidgetChartLegendComponent } from './components/widgets/widget-chart-legend/widget-chart-legend.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HeaderComponent,
    TableListComponent,
    SnackErrorComponent,
    SnackSuccessComponent,
    SnackWarningComponent,
    SnackLoadingComponent,
    SnackInfoComponent,
    DeliveryBlockComponent,
    DragAndDropCardComponent,
    ConfirmationDialogComponent,
    MediaFooterComponent,
    EventsCounterComponent,
    AddressResumeInformationsComponent,
    AddressActionsComponent,
    HeaderTitleComponent,
    SearchBarComponent,
    SavPricesComponent,
    DateFilterComponent,
    SavFilterComponent,
    ZoneFilterComponent,
    DeliveryWarningComponent,
    ProfilComponent,
    ProfilPictureComponent,
    HomeClientComponent,
    TypologyComponent,
    IsArrayPipe,
    SelectMomentComponent,
    RangeDateFilterComponent,
    EllipsisPipe,
    CarrierSelectorComponent,
    CarrierModelCsvSelectorComponent,
    ProgessLoaderComponent,
    DeliveriesFilterDialogComponent,
    ChefingCommentSelectorComponent,
    ChefingSelectorComponent,
    InputHandlerComponent,
    InputComponent,
    ParenthesesPipe,
    HistoryCollapsePanelComponent,
    ChipsInputComponent,
    DeliveryExpansionTableComponent,
    DeliveryExpansionTableComponent,
    CategoryPlanningDetailsComponent,
    GeneralInformationsBlockComponent,
    ItemPlanningDetailsComponent,
    MomentPlanningDetailsComponent,
    SharedPlanningComponent,
    SubCategoryPlanningDetailsComponent,
    ProgressLoaderDialogComponent,
    ItemPlanningDetailsDialogComponent,
    HourFilterComponent,
    ChipsSelectorComponent,
    DateInputComponent,
    InputDialogComponent,
    InputAutocompleteComponent,
    SelectMultipleComponent,
    GlobalSearchBarComponent,
    WidgetChartLegendComponent,
    ChipListComponent,
    ChipComponent,
    CloseButtonComponent,
    DateRangeComponent,
    DividerComponent,
    IconComponent,
    IconButtonComponent,
    MenuComponent,
    ToggleComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClipboardModule,
    ScrollingModule
  ],
  exports: [
    ReactiveFormsModule,
    CommonModule,
    HeaderComponent,
    MaterialModule,
    TableListComponent,
    SnackErrorComponent,
    SnackSuccessComponent,
    SnackWarningComponent,
    SnackLoadingComponent,
    SnackInfoComponent,
    DeliveryBlockComponent,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    DragAndDropCardComponent,
    MediaFooterComponent,
    SearchBarComponent,
    TypologyComponent,
    IsArrayPipe,
    SelectMomentComponent,
    EllipsisPipe,
    RangeDateFilterComponent,
    CarrierSelectorComponent,
    CarrierModelCsvSelectorComponent,
    ClipboardModule,
    ProgessLoaderComponent,
    InputHandlerComponent,
    ChefingCommentSelectorComponent,
    ChefingSelectorComponent,
    ChipsSelectorComponent,
    InputComponent,
    HistoryCollapsePanelComponent,
    ChipsInputComponent,
    DeliveryExpansionTableComponent,
    CategoryPlanningDetailsComponent,
    GeneralInformationsBlockComponent,
    ItemPlanningDetailsComponent,
    MomentPlanningDetailsComponent,
    SharedPlanningComponent,
    SubCategoryPlanningDetailsComponent,
    DateInputComponent,
    InputAutocompleteComponent,
    SelectMultipleComponent,
    GlobalSearchBarComponent,
    WidgetChartLegendComponent,
    CloseButtonComponent,
    MenuComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ProgressLoaderDialogComponent,
    DeliveriesFilterDialogComponent,
    ItemPlanningDetailsDialogComponent
  ]
})
export class SharedModule {
}
