import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { SnackService } from '../services/snack.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  // Variables, Input and Outputs

  // Constructor
  constructor(
    private authService: AuthService,
    private router: Router,
    private snack: SnackService
  ) {
  }

  /**
   * Intercept Function.
   * For each http request, we will check the autheticity of the user thanks to the bearer and the base url
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (this.authService.eocUserValue && this.authService.eocUserValue.bearer) {
      req = req.clone({
        setHeaders: {
          authorization: `Bearer ${this.authService.eocUserValue.bearer}`,
        },
      });
    }
    return next.handle(req).pipe(catchError(err => this.handleAuthError(err)));
  }

  /**
   * If there's an error, we disconnect the user and redirect him to the 'authentification/client' page
   * @param err
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.error('👀', err);
    if (err.status === 401) {
      this.authService.logout();
      this.router.navigate(['authentification']).then();
      return throwError(err.message);
    }
    if (![409].includes(err.status)) {
      this.snack.open('error', 'Oops, une erreur est survenue... 👀');
      return throwError(err);
    }
    return throwError(err);
  }
}
