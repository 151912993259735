import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field/form-field';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() emitValues: boolean = false;

  @Input() form: FormGroup;
  @Input() label: string;
  @Input() type?: string = 'text';
  @Input() model?: string;
  @Input() required?: boolean = false;
  @Input() appearance?: MatFormFieldAppearance = 'outline';
  @Input() controlName: string;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
