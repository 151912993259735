import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BasicAppearance, MenuControlAppearance } from '@core/models/left-menu.model';
import { User } from '@core/services/auth.service';
import { SharedService } from '@core/services/shared.service';
import { MatMenu } from '@shared/models/mat-menu.model';
import { profilMenu, settingMenu } from '@shared/utils/mat-menu.util';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() user: User;
  @Input() menus: MenuControlAppearance[];
  @Input() subMenus: BasicAppearance[];
  @Input() eventInfo: any;

  @Output() logoutE: EventEmitter<any> = new EventEmitter<any>();
  @Output() navigateE: EventEmitter<any> = new EventEmitter<any>();
  @Output() openMoreEventInformationE: EventEmitter<any> = new EventEmitter<any>();

  isPublic: boolean = this.router.url.split('/')[1] === 'public';
  activatedFilters: {value: string, icon: string, displayValue: string}[] = [];
  profilMenu: MatMenu[] = profilMenu;
  settingMenu: MatMenu[] = settingMenu;
  alchimiiLogoPath: string = '/assets/svgs/alchimii_logo_white.svg'; // or alchimii_symbol.svg

  constructor(
    private router: Router,
    private sharedService: SharedService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.activatedFilters = [];
      }
    });
  }

  ngOnInit(): void {
    this.sharedService.subscribeToActivatedFilters()
      .subscribe((filters) => {
        if (filters) {
          this.activatedFilters = filters;
        } else {
          this.activatedFilters = null;
        }
      });
  }

  navigate(...links: string[]): void {
    this.navigateE.emit(links);
  }

  openMoreEventInformation(): void {
    this.openMoreEventInformationE.emit({ role: this.user.role });
  }

  removeActiveFilters(chip: { value: string; icon: string }): void {
    const findItemIndex = this.activatedFilters.findIndex(
      activatedFilter => activatedFilter.value === chip.value && activatedFilter.icon === chip.icon
    );
    this.activatedFilters.splice(findItemIndex, 1);
    this.sharedService.setActivatedFilters(this.activatedFilters);
  }

  menuClicked($event: MatMenu): void {
    if ($event.value === 'logout') {
      this.logoutE.emit();
    } else {
      this.navigateE.emit([$event.value]);
    }
  }
}
