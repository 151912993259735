import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BasicAppearance, MenuControlAppearance, MenusControls } from '@core/models/left-menu.model';
import { SnackService } from '@core/services/snack.service';
import { menuses } from '@core/utils/menu.util';
import { EventDetailDialogComponent } from '@features/ops/dialogs/event-detail-dialog/event-detail-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/services/event.service';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService, User } from '../../services/auth.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {

  isPublic: boolean = this.router.url.split('/')[1] === 'public';
  isLoginPage: boolean;
  menuses: MenusControls = menuses;

  user: User;
  menus: MenuControlAppearance[] = [];
  subMenus: BasicAppearance[];
  users: User[] = [];
  eventInfo: any;
  id: string = '';
  role: string = '';
  subscriptionArray: Subscription[] = [];

  // Constructor
  constructor(
    private authService: AuthService,
    public sharedService: SharedService,
    public translate: TranslateService,
    private eventService: EventService,
    private snackService: SnackService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.subscriptionArray.push(
      this.router.events.subscribe((event: Event) => {
        this.isLoginPage = !!this.router.url.match(/\/authentification/);
        if (event instanceof NavigationEnd) {
          this.initInformationFromUrl(this.router.url);
        }
      }));

    this.setLanguages();
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf('trident') > -1) {
      alert('Notre application est mal supportée par Internet Explorer (Vous pouvez tout de même l\'utiliser). Pour une meilleure expérience, nous vous invitons à télécharger un autre navigateur.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptionArray.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  initInformationFromUrl(url: string): void {
    this.user = this.authService.eocUserValue;
    const regex = /\/(\w+)\/(evenements|delivery-management|profil|parametrage|repertoire|repertories)(\/(\w{24}))?/g;
    const matchUrl = regex.exec(url);
    this.role = 'client';
    this.subMenus = null;
    if (matchUrl) {
      if (matchUrl[1]) {
        this.role = matchUrl[1];
      }
      if (matchUrl[4]) {
        this.id = matchUrl[4];
        if (!this.eventInfo) {
          this.fetchEventInfo();
        }
        if (matchUrl[2]) {
          const menuFind = this.menuses[this.role].find(menu => menu.subChildName.toLowerCase() === matchUrl[2].toLowerCase());
          if (menuFind) {
            this.subMenus = menuFind.children;
          }
        }
      } else {
        if (matchUrl[2] && (matchUrl[2] === 'parametrage' || matchUrl[2] === 'delivery-management')) {
          const menuFind = this.menuses[this.role]
            .find(menu => menu.subChildName && menu.subChildName.toLowerCase() === matchUrl[2].toLowerCase());
          if (menuFind) {
            this.subMenus = menuFind.children;
          }
          if (matchUrl[2] === 'delivery-management') {
            this.eventInfo = null;
          }
        } else {
          this.id = '';
          this.eventInfo = null;
          this.users = [];
        }
      }
    } else {
      if (this.user?.role === 'guest') {
        this.sharedService.sharedEventInfo
          .pipe(first(res => res))
          .subscribe((event) => {
            if (event) {
              this.id = event._id;
              this.fetchEventInfo();
            }
          });
      } else {
        this.id = '';
        this.eventInfo = null;
        this.users = [];
      }
    }
    this.menus = this.menuses[this.role];
    if (this.eventInfo) {
      this.sharedService.setTitle(`Espace Client - ${this.eventInfo.name} - ${this.eventInfo.customer.company}`);
    } else {
      this.sharedService.setTitle(`Espace Client`);
    }
  }

  fetchEventInfo(): void {
    this.eventService.findById(this.id)
      .subscribe(eventInforAndUsers => {
        this.sharedService.setEventEnums(eventInforAndUsers.enums);
        this.eventInfo = eventInforAndUsers.event;
        this.sharedService.setEventInfo(this.eventInfo);
        this.users = eventInforAndUsers.users;
        if (this.eventInfo) {
          this.sharedService.setTitle(`Espace Client - ${this.eventInfo.name} - ${this.eventInfo.customer.company}`);
        } else {
          this.sharedService.setTitle(`Espace Client`);
        }
      });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate([`authentification/`]);
  }

  navigate(urls: string[]): void {
    let url = urls.join('/').replace(/{id}/, this.id);
    if (urls.find(link => link.includes('{id}')) && !this.id) {
      url = urls[0];
    }
    let prefix = '';
    if (this.role === 'organisateur') {
      prefix = 'client/';
    }

    if (url === 'profil') {
      prefix = prefix || this.role;
      this.router.navigate([`${prefix}/${url}`]);
      return;
    }
    this.router.navigate([`${prefix}${this.role}/${url}`]);
  }

  /**
   * Function called to set available languages and default language
   */
  setLanguages(): void {
    this.translate.addLangs(['en', 'fr', 'us', 'eu', 'es']);
    let language = 'fr';
    this.subscriptionArray.push(
      this.sharedService
        .sharedLanguage
        .subscribe(lang => {
          if (!lang) {
            if (localStorage.getItem('chefingLanguage')) {
              language = localStorage.getItem('chefingLanguage').toString();
            }
          } else {
            language = lang;
          }
          this.setupTranslation(language);
        })
    );
  }

  setupTranslation(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  openMoreEventInformation($event: { role: string }): void {
    if (this.eventInfo) {
      this.dialog.open(EventDetailDialogComponent, {
        data: {
          event: this.eventInfo,
          users: this.users,
          role: $event.role
        },
        width: '900px',
        height: '500px',
        autoFocus: false,
        disableClose: true
      })
        .afterClosed()
        .subscribe(event => {
          if (event) {
            this.eventService.updateGeneralInformations(this.eventInfo._id, event)
              .subscribe(eventInforAndUsers => {
                this.eventInfo = eventInforAndUsers.event;
                this.users = eventInforAndUsers.users;
                this.snackService.open('success', 'Événement mis à jour!');
              });
          }
        });
    }
  }
}
