import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedFilters } from '@features/ops/models/ops.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { retry } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  /* ----- Interface Client ----- */
  private language: BehaviorSubject<any> = new BehaviorSubject(null);
  private eventInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  private activatedFilters$: BehaviorSubject<any> = new BehaviorSubject(null);

  /* ----- Charlie ----- */
  private page: Subject<any> = new Subject<any>();
  private event: Subject<any> = new Subject<any>();
  private status: Subject<any> = new Subject<any>();
  private plannificationId: Subject<any> = new Subject<any>();
  private generalInfoPreviewData: Subject<any> = new Subject<any>();
  private momentPreviewData: Subject<any> = new Subject<any>();

  /* ----- Enums ----- */
  private eventEnums$: BehaviorSubject<{}> = new BehaviorSubject({});

  /* ----- Moment ----- */
  private duplicateMoment$: BehaviorSubject<{}> = new BehaviorSubject(false); // temporary store for duplicate moment
  private showBranding$: Subject<boolean> = new Subject<boolean>(); // show branding, avoid emitters

  /* ----- Interface Client ----- */
  sharedLanguage: Observable<any> = this.language.asObservable();
  sharedEventInfo: Observable<any> = this.eventInfo$.asObservable();
  sharedActivatedFilters: Observable<any> = this.activatedFilters$.asObservable();

  /* ----- Charlie ----- */
  sharedPage: Observable<any> = this.page.asObservable();
  sharedEvent: Observable<any> = this.event.asObservable();
  sharedStatus: Observable<any> = this.status.asObservable();
  sharedPlannificationId: Observable<any> = this.plannificationId.asObservable();
  sharedGeneralInfoPreviewData: Observable<any> = this.generalInfoPreviewData.asObservable();
  sharedMomentPreviewData: Observable<any> = this.momentPreviewData.asObservable();

  /* ----- Shared Component Menu Component ----- */
  public menuEmitter: EventEmitter<boolean>;

  subscribeToShowBranding(): Observable<any> {
    return this.showBranding$.asObservable();
  }

  subscribeToduplicateMoment(): Observable<any> {
    return this.duplicateMoment$.asObservable();
  }

  subscribeToActivatedFilters(): Observable<any> {
    return this.activatedFilters$.asObservable();
  }

  constructor(
    private titleService: Title
  ) {
    this.menuEmitter = new EventEmitter<boolean>();
  }

  public get getEventEnums(): any {
    return this.eventEnums$.value;
  }

  public get getCurrentMomentToDuplicate(): any {
    return this.duplicateMoment$.value;
  }

  public get getEventInfo(): any {
    return this.eventInfo$.value;
  }

  public get getActivatedFilters(): any {
    return this.activatedFilters$.value;
  }

  /* ----- Interface Client ----- */

  setLanguage(language: string): void {
    this.language.next(language);
  }

  setEventInfo(event: any): void {
    this.eventInfo$.next(event);
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  setActivatedFilters(activatedFilters: {value: string, icon: string}[]): void {
    this.activatedFilters$.next(activatedFilters);
  }

  /* ----- Charlie ----- */
  setPage(page: string): void {
    this.page.next(page);
  }

  setEvent(event): void {
    this.event.next(event);
  }

  setStatus(status): void {
    this.status.next(status);
  }

  setPlannificationId(plannificationId): void {
    this.plannificationId.next(plannificationId);
  }

  setGeneralInfoPreviewData(generalInfoPreviewData: Object): void {
    this.generalInfoPreviewData.next(generalInfoPreviewData);
  }

  setMomentPreviewData(momentPreviewData: Object): void {
    this.momentPreviewData.next(momentPreviewData);
  }

  setEventEnums(value: any): void {
    this.eventEnums$.next(value);
  }

  setMomentToDuplicate(value: any): void {
    this.duplicateMoment$.next(value);
  }

  showBranding(value: boolean): void {
    this.showBranding$.next(value);
  }

  open(): void {
    this.menuEmitter.emit(true);
  }
  
  close(): void {
    this.menuEmitter.emit(false);
  }

}
