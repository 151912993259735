import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SnackService } from '../services/snack.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // Variables, Input and Outputs

  // Constructor
  constructor(
    private router: Router,
    private authService: AuthService,
    private snack: SnackService
  ) { }

  /**
   * Can Activate Function. Used into routing modules
   * @param next
   * @param state
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.authService.eocUserValue;
    if (!currentUser) {
      return false;
    }

    if (currentUser) {
      if (!this.isAuthorized(currentUser.roles, next)) {
        this.router.navigate(['/']).then((navigated: boolean) => {
          if (navigated) {
            this.snack.open('error', '🚨 Vous n\'avez pas les droits requis pour accéder à cette page... 🚨');
          }
        });
        return false;
      }
      return true;
    }
    return true;
  }

  isAuthorized(userRoles: string[], next: ActivatedRouteSnapshot): boolean {
    if ((!next.data?.roles) || (next.params.type && userRoles?.includes(next.params.type.toUpperCase()))) {
      return true;
    }
    if (next.data.roles) {
      return !next.data.roles.map(role => userRoles.includes(role)).some(r => !r);
    }

    return false;
  }

}
