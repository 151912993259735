<div class="flex-column" style="position: relative;" [formGroup]="form">
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>

    <div class="flex-column" style="margin-top: 15px;">
        <mat-label>Nom</mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Nom</mat-label>
            <input matInput autocomplete="off" formControlName="lastname" (keyup.enter)="createContact()" required>
        </mat-form-field>
    </div>

    <div class="flex-column" style="margin-top: 15px;">
        <mat-label>Prénom</mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Prénom</mat-label>
            <input matInput autocomplete="off" formControlName="firstname" (keyup.enter)="createContact()"  required>
        </mat-form-field>
    </div>

    <div class="flex-column" style="margin-top: 15px;">
        <mat-label>Email</mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput autocomplete="off" formControlName="email" (keyup.enter)="createContact()" >
        </mat-form-field>
    </div>

    <div class="flex-column" style="margin-top: 15px;">
        <mat-label>Téléphone</mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Téléphone</mat-label>
            <input matInput autocomplete="off" formControlName="tel" (keyup.enter)="createContact()"  required>
        </mat-form-field>
    </div>

    <div class="flex-column" style="margin-top: 15px;">
        <mat-label>Entreprise</mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Entreprise</mat-label>
            <input matInput autocomplete="off" formControlName="company" (keyup.enter)="createContact()"  required>
        </mat-form-field>
    </div>

    <div class="flex-column" style="margin-top: 15px;">
        <div class="button-container">
            <button mat-button [disabled]="!form.valid" class="button-enabled" (click)="createContact();"
                [ngClass]="{'button-disabled': !form.valid, 'button-enabled': form.valid}">
                <span style="color: var(--white);">Valider</span>
            </button>
        </div>
    </div>
</div>
