import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Contact } from '@features/repertory/models/repertory-view.model';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {

  @Input() contact: Contact;
  @Output() updateContactE: EventEmitter<Contact> = new EventEmitter<Contact>();
  form: FormGroup;
  isFormDisabled: boolean = true;

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      firstname: this.fb.control(''),
      lastname: this.fb.control(''),
      tel: this.fb.control('') ,
      email: this.fb.control(''),
      company: this.fb.control('')
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(simpleChange: SimpleChanges): void {
    Object.keys(this.contact).forEach(key => {
      if (this.contact[key].length) {
        this.isFormDisabled = false;
      }
    });
    if (simpleChange && simpleChange.contact && simpleChange.contact.currentValue) {
      this.patchForm();
    }
  }

  patchForm() {
    this.form.patchValue({
      firstname: this.contact.firstname || '',
      lastname: this.contact.lastname || '',
      tel: this.contact.tel || '',
      email: this.contact.email || '',
      company: this.contact.company || ''
    });
  }

  updateContact() {
    this.updateContactE.emit({
      _id: this.contact._id,
      ...this.form.value
    });
  }

}
