<div class="header-data">
    <span>
      {{ eventInfo.customer.firstname }} {{ eventInfo.customer.lastname }}
      <mat-icon
        *ngIf="role === 'admin'"
        [ngClass]="eventInfo.alreadyLoggedIn ? 'loggedIn' : 'notLoggedIn'"
        [matTooltip]="eventInfo.alreadyLoggedIn ? 's\'est déjà connecté' : 'ne s\'est jamais connecté'">
        {{ eventInfo.alreadyLoggedIn ? 'check_circle': 'do_disturb_on'}}
      </mat-icon>
    </span>
    <div class="vl"></div>
    <span>{{ eventInfo.customer.company }}</span>
    <div class="vl"></div>
    <span>{{ eventInfo.name}}</span>
    <div class="vl"></div>
    <span>{{ eventInfo.referenceNumber}}</span>
    <div class="vl"></div>
    <button mat-icon-button *ngIf="role !== 'guest'" (click)="openMoreEventInformation()">
      <mat-icon>info_outline</mat-icon>
    </button>
</div>