import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface User {
  _id: string;
  bearer: string;
  firstname: string;
  name: string;
  role: string;
  roles: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiBase: string = environment.baseUrl;
  endpoint: string = 'authentication';

  private eocUserSubject: BehaviorSubject<User>;

  constructor(private http: HttpClient) {
    this.eocUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('eoc-user')));
  }

  public get eocUserValue(): User {
    return this.eocUserSubject.value;
  }

  login({ email, password }): Observable<any> {
    const url = `${this.apiBase}/${this.endpoint}/login`;
    return this.http.post<User>(url, { email, password }).pipe(
      map((data: User) => {
        localStorage.setItem('eoc-user', JSON.stringify(data));
        this.eocUserSubject.next(data);
        return data;
      })
    );
  }

  logout(): void {
    localStorage.removeItem('eoc-user');
    this.eocUserSubject.next(null);
  }

  /**
   * @param email -
   */
  forgotPassword(email: string): Observable<any> {
    const url = `${this.apiBase}/${this.endpoint}/forgot`;
    return this.http.post(url, { email });
  }

  /**
   * Vérifie la validité du token
   * @param token -
   */
  isTokenAlive(token: string): Observable<any> {
    const url = `${this.apiBase}/${this.endpoint}/isTokenAlive`;
    return this.http.post<any>(url, { token });
  }


  /**
   * @param password - nouveau mot de passe à enregistrer
   * @param email -
   */
  resetPassword(payload: { token: string, password: string }): Observable<any> {
    const url = `${this.apiBase}/${this.endpoint}/resetPassword`;
    return this.http.post(url, payload);
  }

  loginGuest({ email, secretCode, jwt }: any): Observable<any> {
    const url = `${this.apiBase}/${this.endpoint}/loginGuest`;
    return this.http.post<User>(url, { email, secretCode, jwt }).pipe(
      map((data: User) => {
        localStorage.setItem('eoc-user', JSON.stringify(data));
        this.eocUserSubject.next(data);
        return data;
      })
    );
  }

  generateSecretCode({email, jwt}: {email: string, jwt: string}): Observable<any> {
    const url = `${this.apiBase}/${this.endpoint}/generate-secret-code`;
    return this.http.post<User>(url, { email, jwt });
  }

}
