import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss']
})
export class ChipsInputComponent implements OnInit {


  // @Input() emitValues: boolean = false;

  @Input() form: FormGroup;
  @Input() label: string;
  @Input() appearance?: any = 'outline';
  @Input() controlName: string;

  // @Output() typing: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  values(ctrl: string): any {
    return this.form.get(ctrl).value;
  }

  add($event: any, controlName: string): void {
    const entry = ($event.value || '').trim();
    if (entry) {
      this.values(controlName).push(entry);
    }
    // Clear the input value
    if ($event?.input?.value) {
      ($event as MatChipInputEvent).input.value = '';
    } else {
      $event.value = '';
    }
  }

  remove(v: any, controlName: string): void {
    const index = this.values(controlName).indexOf(v);

    if (index >= 0) {
      this.values(controlName).splice(index, 1);
    }
  }
}
