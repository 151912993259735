import { stringify } from '@angular/compiler/src/util';
import { AddressBlock } from '@features/promoter/models/promoter-address.model';
import { SearchParams } from '@shared/models/search-params.model';

export function computeQuery(reqQuery: SearchParams): string {
  return Object
    .keys(reqQuery)
    .filter(key => typeof reqQuery[key] === 'number' || reqQuery[key])
    .map(key => `${key}=${reqQuery[key]}`)
    .join('&');
}

export function initQuery(): SearchParams {
  return {
    limit: 15,
    page: 0,
    q: '',
    zones: [],
    deliveryDate: '',
    savs: [],
    savExists: '',
    validated: '',
    status: '',
    deliveryMoment: '',
    pickupMoment: '',
    deliveryDateStart: '',
    deliveryDateEnd: '',
    zipCodes: [],
    countries: [],
    cities: [],
    operationUser: '',
    verificationUser: '',
    logisticUser: '',
    carriers: [],
    chefingComments: [],
    referenceNumbers: [],
    specificities: [],
    clientSpecificities: [],
    warnings: [],
    letter: '',
    typology: '',
    promoterId: '',
    date: '',
    am: false,
    pm: false,
    startHour: '',
    endHour: '',
    moments: [],
    eventId: '',
    isMomentsWithBundlesViewActivated: false
  };
}

/**
 * Construire la query du panel sur lequel on se trouve
 */
export const computeBlockQuery = (block: AddressBlock, showMore: boolean): SearchParams => {
  block.query.status = block.name;
  if (showMore) {
    block.query.page++;
  } else {
    block.query.page = 0;
  }
  block.query.limit = 25;
  block.query.validated = block.name === 'validated' ? 'true' : '';
  return block.query;
};
