<div class="flex-column" style="position: relative;">
    
    <!-- Close Button -->
    <app-close-button></app-close-button>

    <div class="dialog-container">

        <!-- Title -->
        <span class="title">Ajouter des contacts au répertoire</span>

        <!-- Button Import Document -->
        <button class="button-import-document" type="file" mat-button (click)="fileInput.click();">
            <mat-icon>get_app</mat-icon>
            J'importe des contacts
            <input #fileInput class="button-import-document" (change)="importFile($event)" type="file" accept=".csv" style="opacity: 0; display: none;">
        </button>

        <!-- Sub Title Import Document-->
        <span class="subtitle-import-document">J'importe mes contacts sous forme de fichier CSV à l'aide du model ci-dessous.</span>

        <!-- Download Here CTA-->
        <span class="download-model" (click)="downloadModel()">Télécharger le model ici</span>


        <span class="subtitle-warning" style="margin-top: 15px; font-weight: 500;">Attention !</span>
        <span class="subtitle-warning" style="font-weight: 500;">Les séparateurs doivent être en point-virgule ';'</span>

    </div>

</div>
