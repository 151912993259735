import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SnackService } from '@core/services/snack.service';
import { AddContactToRepertoryDialogComponent } from '@features/repertory/components/dialogs/add-contact-to-repertory-dialog/add-contact-to-repertory-dialog.component';
import { DeleteContactToRepertoryDialogComponent } from '@features/repertory/components/dialogs/delete-contact-to-repertory-dialog/delete-contact-to-repertory-dialog.component';
import { ImportContactsToRepertoryDialogComponent } from '@features/repertory/components/dialogs/import-contacts-to-repertory-dialog/import-contacts-to-repertory-dialog.component';
import { ShareRepertoryAccessDialogComponent } from '@features/repertory/components/dialogs/share-repertory-access-dialog/share-repertory-access-dialog.component';
import { Contact, RepertoryDataSource } from '@features/repertory/models/repertory-view.model';
import { RepertoryService } from '@features/repertory/services/repertory.service';
import { fillContactsByLetter, handleCheckedContacts, initCurrentContact, initRepertoryDataSource, removeContactsAfterDeletion } from '@features/repertory/utils/repertory-view.util';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MenuOrder } from '@shared/models/menu-order.model';
import { SearchParams } from '@shared/models/search-params.model';
import { menuRepertoryOrder } from '@shared/utils/menu-order';
import { initQuery } from '@shared/utils/query.utils';
import { createAndDownloadFile, returnSearchParams } from '@shared/utils/shared.util';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-repertory-view',
  templateUrl: './repertory-view.component.html',
  styleUrls: ['./repertory-view.component.scss']
})
export class RepertoryViewComponent implements OnInit {

  urlQuery: SearchParams = initQuery();
  dataSource: RepertoryDataSource[] = initRepertoryDataSource;
  id: string = this.route.snapshot.params.id;
  currentRoute: string = '';
  subscribeToRepertorySubscription: Subscription;
  currentContact: Contact = initCurrentContact();
  isLoading: boolean = false;
  contactsToDelete: Contact[] = [];
  isDeleteButtonDisabled: boolean = true;
  limitDate: string = '';
  repertoryLimitDate: string = '';
  isContactDetailsDisabled: boolean = true;
  isRepertoryLimitDateDisplayed: boolean = false;
  menuOrder: MenuOrder[] = menuRepertoryOrder;

  constructor(
    private snack: SnackService,
    private dialog: MatDialog,
    private repertoryService: RepertoryService,
    private route: ActivatedRoute
  ) { 
    moment.locale('fr');
  }

  ngOnInit(): void {
    this.repertoryService.subscribeToRepertory()
      .subscribe((data: RepertoryDataSource[]) => {
        if (data) {
          this.dataSource = fillContactsByLetter(this.dataSource, data);

          if (this.urlQuery.q.length >= 3) {
            this.dataSource.forEach(data => {
              if (!data.datas.length) {
                this.dataSource = this.dataSource.filter(d => d.letter != data.letter);
              } else {
                data.hasBeenLoaded = true;
              }
            });
          }

          this.isLoading = false;
        }
      });
    this.repertoryService.getLimitDate().subscribe(
      (res) => {
        if (res.limitDate) {
          this.limitDate = res.limitDate;
          const today = moment().tz("Europe/Paris").startOf('day').format();
          this.isRepertoryLimitDateDisplayed = moment(today).isSameOrBefore(moment(this.limitDate));
          this.repertoryLimitDate = moment(this.limitDate).format('LL');
        }
      }
    );
    Object.keys(this.currentContact).forEach((key) => {if (this.currentContact[key].length) { this.isContactDetailsDisabled = false } })
  }

  ngOnDestroy(): void {
    if (this.subscribeToRepertorySubscription) { this.subscribeToRepertorySubscription.unsubscribe(); }
  }

  onSearch(searchString?: string, page?: number): void {
    this.urlQuery.q = '';
    const searchParams: { page: number, q: string } = returnSearchParams(searchString, page);
    this.urlQuery.q = searchParams.q ? searchParams.q : '';
    this.urlQuery.page = searchParams.page ? searchParams.page : 0;
    this.dataSource.forEach(data => {
      data.datas = [];
    });
    this.urlQuery.letter = '';
    if (this.urlQuery.q.length >= 3) {
      this.repertoryService.find(this.urlQuery);
    } else {
      this.urlQuery.q = '';
      this.dataSource = initRepertoryDataSource;
      this.dataSource.forEach((data) => {
        data.hasBeenLoaded = false;
      });
    }
  }

  setCurrentContact(event: Contact) {
    this.currentContact = event;
    this.isContactDetailsDisabled = false;
  }

  updateContact($event: Contact) {
    const _id = $event._id;
    delete $event._id;
    this.repertoryService.updateById(_id, $event, this.urlQuery);
  }

  findContacts($event: string) {
    this.isLoading = true;
    const findDatasByLetter = this.dataSource.find(data => data.letter.toLowerCase() === $event.toLowerCase());
    if (findDatasByLetter && !findDatasByLetter.hasBeenLoaded) {
      this.urlQuery.letter = $event;
      findDatasByLetter.hasBeenLoaded = true;
      this.repertoryService.find(this.urlQuery);
    } else {
      this.isLoading = false;
    }
  }

  contactHasBeenChecked($event: {checked: boolean, contact: Contact}) {
    const result = handleCheckedContacts($event, this.contactsToDelete, this.isDeleteButtonDisabled);
    this.contactsToDelete = result.contactsToDelete;
    this.isDeleteButtonDisabled = result.isDeleteButtonDisabled;
  }

  trackByContacts(contacts: Contact[]) {
    return contacts;
  }

  openAddContactToRepertoryDialog() {
    this.dialog.open(AddContactToRepertoryDialogComponent, {
      data: {},
      width: '500px',
      height: '665px',
      autoFocus: false,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: false,
      disableClose: true
    })
      .afterClosed()
      .subscribe((contact) => {
        if (contact) {
          this.urlQuery.letter = contact.lastname.charAt(0);
          const openDialog = (config) => this.dialog.open(ConfirmationDialogComponent, config).afterClosed();
          const succesSnack = () => this.snack.open('success', 'Ajouté avec succès');
          const conflictConfig = { data: { title: 'ATTENTION', content: 'Cette personne existe déjà dans votre répertoire, voulez-vous l\'ajouter ?' } };
          this.repertoryService
          .createContactObs(contact, this.urlQuery)
          .subscribe(
            succesSnack,
            (err: HttpResponse<any>) => {
              if (err?.status === 409) {
                openDialog(conflictConfig)
                  .subscribe((ok) => {
                    if (ok) {
                      contact.new = true;
                      this.repertoryService
                        .createContactObs(contact, this.urlQuery)
                        .subscribe(
                          (res: RepertoryDataSource[]) => {
                            succesSnack;
                            this.dataSource = fillContactsByLetter(this.dataSource, res);
                          }
                        );
                    }
                  });
              }
            }
          );

        }
      });
  }

  openDeleteContactToRepertoryDialog() {
    if (!this.contactsToDelete.length) {
      this.snack.open('warning', 'Veuillez séléctionner un ou plusieurs contacts')
    } else {
      this.dialog.open(DeleteContactToRepertoryDialogComponent, {
        data: {
          title: 'Êtes-vous sûr ?',
          content: `Êtes-vous sûr de vouloir supprimer ces contacts ?`,
          contacts: this.contactsToDelete
        },
        width: '600px',
        height: '450px',
        autoFocus: false
      }).afterClosed()
        .subscribe((contactIds: string[]) => {
          if (contactIds.length) {
            this.repertoryService.deleteContact(contactIds, this.urlQuery);
            removeContactsAfterDeletion(contactIds, this.dataSource);
            this.currentContact = initCurrentContact();
            this.contactsToDelete = [];
            this.isDeleteButtonDisabled = true;
          }
        });
    }
  }

  openShareRepertoryAccessDialog() {
    this.urlQuery.letter = '';
    this.urlQuery.q = '';
    this.dialog.open(ShareRepertoryAccessDialogComponent, {
      data: {
        limitDate: this.limitDate
      },
      width: '550px',
      height: '330px',
      autoFocus: false
    }).afterClosed()
      .subscribe(({ date }: { date: string }) => {
        if (date) {
          this.repertoryService.setLimitDate(date).subscribe(
            (res) => {
              this.snack.open('success', 'Date limite bien mise à jour');
              this.repertoryService.getLimitDate().subscribe(
                (res) => {
                  if (res.limitDate) {
                    this.limitDate = res.limitDate;
                    const today = moment().tz("Europe/Paris").startOf('day').format();
                    this.isRepertoryLimitDateDisplayed = moment(today).isSameOrBefore(moment(this.limitDate));
                    this.repertoryLimitDate = moment(this.limitDate).format('LL');
                  }
                }
              );
            },
            (err) => {
              this.snack.open('success', 'La date limite n\'a pas été mise à jour');
            }
          )
        }
      });
  }

  openImportContactsToRepertoryDialog() {
    const dialog = this.dialog.open(ImportContactsToRepertoryDialogComponent, {
      data: {},
      autoFocus: false
    });

    dialog.componentInstance.downloadModelE.subscribe((res) => {
      const headers = 'Prénom;Nom;Email;Téléphone;Entreprise\n';
      createAndDownloadFile('Model_Contacts', 'csv', headers);
    });

    dialog.afterClosed().subscribe((res: {csv: string}) => {
      if (res) {
        const tempLines = res.csv.split(/\r\n|\n/);
        tempLines.shift();
        const lines = tempLines.filter((line) => line);
        this.repertoryService.importCsv({csv: lines}, this.urlQuery);
      }
    });
  }

}
