<div class="flex-column" [formGroup]="form" style="position: relative; justify-content: space-between; height: 100%;">
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>

    <span class="title">Support</span>

    <span>Si vous en avez besoin vous pouvez nous partager les informations sur les contacts présent dans votre répertoire</span>

    <mat-form-field class="mat-form-field-filter" appearance="outline" style="cursor: pointer;" (click)="picker.open()">    
        <mat-label>Sélectionnez une date limite</mat-label>
        <input matInput [matDatepicker]="picker" autocomplete="off" formControlName="date" readonly style="cursor: pointer;">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
    </mat-form-field>

    <div class="button-container">
        <button mat-button (click)="confirm()" [disabled]="!form.valid" for="valider">Valider</button>
    </div>
</div>