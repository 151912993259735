import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

interface Data {
  limitDate: string
}

@Component({
  selector: 'app-share-repertory-access-dialog',
  templateUrl: './share-repertory-access-dialog.component.html',
  styleUrls: ['./share-repertory-access-dialog.component.scss']
})
export class ShareRepertoryAccessDialogComponent implements OnInit {

  startDate: string = '';
  endDate: string = '';

  form: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ShareRepertoryAccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      date: this.fb.control({ value: '' })
    });
    this.patchFormValues();
  }

  patchFormValues() {
    this.form.patchValue({
      date: this.data.limitDate ? this.data.limitDate : ''
    });
  }

  confirm() {
    this.dialogRef.close({
      date: this.form.get('date').value,
    });
  }

}
