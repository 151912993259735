<button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
    <mat-icon>{{iconMenu}}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let menu of menus">
    <button *ngIf="menu.page === currentPage" [disabled]="menu.disabled" (click)="menuClicked(menu)" mat-menu-item>
      <mat-icon [ngStyle]="{'color': menu.color}">{{menu.icon}}</mat-icon>
      <span>{{menu.label}}</span>
    </button>
  </ng-container>
</mat-menu>