import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-import-contacts-to-repertory-dialog',
  templateUrl: './import-contacts-to-repertory-dialog.component.html',
  styleUrls: ['./import-contacts-to-repertory-dialog.component.scss']
})
export class ImportContactsToRepertoryDialogComponent implements OnInit {

  @Output() downloadModelE: EventEmitter<boolean> = new EventEmitter<boolean>();

  fileToUpload: File = null;
  isFileInputDisplayed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ImportContactsToRepertoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  downloadModel(): void {
    this.downloadModelE.emit(true);
  }

  importFile(e){
    const files = e.target.files;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const csv = event.target.result;
      this.dialogRef.close({csv: csv});
    }
  }

}
