import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '@features/repertory/models/repertory-view.model';

interface Data {
  contacts: Contact[],
  title: string;
  content: string;
}

@Component({
  selector: 'app-delete-contact-to-repertory-dialog',
  templateUrl: './delete-contact-to-repertory-dialog.component.html',
  styleUrls: ['./delete-contact-to-repertory-dialog.component.scss']
})
export class DeleteContactToRepertoryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteContactToRepertoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {}

  ngOnInit() {
  }

  confirm() {
    const contactIds = this.data.contacts.map(contact => contact._id);
    this.dialogRef.close(contactIds);
  }

}
