<div class="title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="cursor: move;">
  <span>{{ data.title }}</span>
</div>
<div *ngIf="!data.link; else link" class="content" [innerHTML]="data.content">
</div>
<ng-template #link>
  <ul>
    <li>
      <a [href]="data.link" target="_blank">{{data.content}}</a>
    </li>
  </ul>
</ng-template>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close color="warn">Annuler</button>
  <button mat-flat-button color="primary" (click)="validate()">Valider</button>
</mat-dialog-actions>
