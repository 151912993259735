<div class="event-detail-dialog">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <form [formGroup]="eventGroup" class="flex-column">
    <span class="general-title">Informations générales</span>
    <span class="section-title">Informations client</span>
    <div class="line-holder">
      <app-input [form]="eventGroup" controlName="firstname" label="Prénom"></app-input>
      <app-input [form]="eventGroup" controlName="lastname" label="Nom"></app-input>
    </div>
    <div class="line-holder">
      <app-input [form]="eventGroup" controlName="tel" label="Téléphone"></app-input>
      <app-input [form]="eventGroup" controlName="company" label="Entreprise"></app-input>
    </div>
    <span class="section-title">Information événement</span>
    <div class="line-holder">
      <app-input [form]="eventGroup" controlName="name" label="Nom de l'évenement"></app-input>
      <app-input [form]="eventGroup" controlName="referenceNumber" label="Numéro de dossier"></app-input>
    </div>
    <div class="line-holder">
      <mat-form-field appearance="outline" class="mat-form-field-style" (click)="picker.open()">
        <mat-label>Début de l'événement</mat-label>
        <input matInput [matDatepicker]="picker" autocomplete="off" formControlName="startDate" readonly>
        <mat-datepicker-toggle disabled="data.role !== 'admin'" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="data.role !== 'admin'"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-form-field-style" (click)="picker1.open()">
        <mat-label>Fin de l'événement</mat-label>
        <input matInput [matDatepicker]="picker1" autocomplete="off" formControlName="endDate" readonly>
        <mat-datepicker-toggle disabled="data.role !== 'admin'" matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 disabled="data.role !== 'admin'"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="line-holder">
      <app-input [form]="eventGroup" type="time" controlName="startHour" label="Heure du début"></app-input>
      <app-input [form]="eventGroup" type="time" controlName="endHour" label="Heure de fin"></app-input>
    </div>
    <div class="line-holder">
      <app-chips-input [form]="eventGroup" controlName="contacts" label="Contact(s)" class="mat-form-field-style"></app-chips-input>
    </div>
    <div class="line-holder">
      <mat-form-field appearance="outline" class="mat-form-field-style">
        <mat-label>Responsable opération</mat-label>
        <mat-select *ngIf="data.role === 'admin'" formControlName="userOperation">
          <mat-option *ngFor="let user of data.users" [value]="user._id">{{user.firstname}} {{user.lastname}}</mat-option>
        </mat-select>
        <mat-select *ngIf="data.role !== 'admin'" formControlName="userOperation">
          <mat-option *ngFor="let user of data.event.users" [value]="user.value._id">{{user.value.firstname}} {{user.value.lastname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="line-holder">
      <mat-form-field appearance="outline" class="mat-form-field-style">
        <mat-label>Responsable vérification</mat-label>
        <mat-select *ngIf="data.role === 'admin'" formControlName="userVerification">
          <mat-option *ngFor="let user of data.users" [value]="user._id">{{user.firstname}} {{user.lastname}}</mat-option>
        </mat-select>
        <mat-select *ngIf="data.role !== 'admin'" formControlName="userVerification">
          <mat-option *ngFor="let user of data.event.users" [value]="user.value._id">{{user.value.firstname}} {{user.value.lastname}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-form-field-style">
        <mat-label>Responsable logisitique</mat-label>
        <mat-select *ngIf="data.role === 'admin'" formControlName="userLogistic">
          <mat-option *ngFor="let user of data.users" [value]="user._id">{{user.firstname}} {{user.lastname}}</mat-option>
        </mat-select>
        <mat-select *ngIf="data.role !== 'admin'" formControlName="userLogistic">
          <mat-option *ngFor="let user of data.event.users" [value]="user.value._id">{{user.value.firstname}} {{user.value.lastname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="line-holder">
      <app-input [form]="eventGroup" controlName="telRespoOps" label="Téléphone"></app-input>
    </div>
    <!--<span class="section-title">Localisation</span>
    <div class="line-holder">
      <app-input [form]="eventGroup" controlName="customerAddress" label="Adresse pour convive"></app-input>
      <app-input [form]="eventGroup" controlName="deliveryAddress" label="Adresse de livraison"></app-input>
    </div>-->
  </form>
</div>
<mat-dialog-actions align="center" *ngIf="data.role === 'admin'">
  <button mat-raised-button type="button" color="primary" [disabled]="!eventGroup.valid" [mat-dialog-close]='eventGroup.value'>Sauvegarder</button>
</mat-dialog-actions>
