import { MenuOrder } from "@shared/models/menu-order.model";

export const menuOpsDispatchOrder: MenuOrder[] = [
    {
        menuName: 'createMoment',
        order: 1
    },
    {
        menuName: 'duplicateMoment',
        order: 2
    },
    {
        menuName: 'groupedAction',
        order: 3
    },
    {
        menuName: 'attachmentsManagement',
        order: 4
    },
    {
        menuName: 'seeBrandings',
        order: 5
    }
];

export const menuOpsPlanningOrder: MenuOrder[] = [
    {
        menuName: 'addUniqAddress',
        order: 1
    },
    {
        menuName: 'addContactFromRepertoryToEvent',
        order: 2
    },
    {
        menuName: 'deleteSelectedAddresses',
        order: 3
    },
    {
        menuName: 'typeform',
        order: 4
    },
    {
        menuName: 'importAddresses',
        order: 5
    },
    {
        menuName: 'exportAddresses',
        order: 6
    },
    {
        menuName: 'createMoment',
        order: 7
    },
    {
        menuName: 'groupedAction',
        order: 8
    },
    {
        menuName: 'filters',
        order: 9
    },
    {
        menuName: 'sms',
        order: 10
    }
];

export const menuOpsSavOrder: MenuOrder[] = [
    {
        menuName: 'createMoment',
        order: 1
    },
    {
        menuName: 'groupedAction',
        order: 2
    }
];

export const menuOpsCommunicationOrder: MenuOrder[] = [
    {
        menuName: 'link',
        order: 1
    },
    {
        menuName: 'exportCsv',
        order: 2
    }
];

export const menuOpsEventsOrder: MenuOrder[] = [
    {
        menuName: 'attachmentsManagement',
        order: 1
    }
];

export const menuOpsDeliveriesOrder: MenuOrder[] = [
    {
        menuName: 'importDeliveryTrackingFile',
        order: 1
    },
    {
        menuName: 'groupedAction',
        order: 2
    },
    {
        menuName: 'buildCsv',
        order: 3
    },
    {
        menuName: 'filters',
        order: 4
    }
];

export const menuPromoterAddressOrder: MenuOrder[] = [
    {
        menuName: 'addUniqAddress',
        order: 1
    },
    {
        menuName: 'addContactFromRepertoryToEvent',
        order: 2
    },
    {
        menuName: 'deleteAllAddressesOfEvent',
        order: 3
    },
    {
        menuName: 'typeform',
        order: 4
    },
    {
        menuName: 'importAddresses',
        order: 5
    },
    {
        menuName: 'exportAddresses',
        order: 6
    },
    {
        menuName: 'groupedAction',
        order: 7
    }
];

export const menuSettingCsvOrder: MenuOrder[] = [
    {
        menuName: 'buildCsv',
        order: 1
    }
];

export const menuRepertoryOrder: MenuOrder[] = [
    {
        menuName: 'addContactToRepertory',
        order: 1
    },
    {
        menuName: 'importContactsToRepertory',
        order: 2
    },
    {
        menuName: 'deleteContactFromRepertory',
        order: 3
    },
    {
        menuName: 'shareRepertoryToAdmin',
        order: 4
    }
];

export const menuSettingInfographyOrder: MenuOrder[] = [
    {
        menuName: 'createNewModel',
        order: 1
    }
];

export const menuOpsGenerateBundleOrder: MenuOrder[] = [
    {
        menuName: 'createParticipant',
        order: 1
    },
    {
        menuName: 'createAddress',
        order: 2
    },
    {
        menuName: 'addressesRepertory',
        order: 3
    },
    {
        menuName: 'createBundle',
        order: 4
    },
    {
        menuName: 'importAddresses',
        order: 5
    },
    {
        menuName: 'exportAddresses',
        order: 6
    },
    {
        menuName: 'generateBundleFilter',
        order: 7
    },
    {
        menuName: 'removeParticipantFromMultipleBundles',
        order: 8
    }
];

export const menuOpsAssociateBundleOrder: MenuOrder[] = [
    {
        menuName: 'createMoment',
        order: 1 
    },
    {
        menuName: 'duplicateMoment',
        order: 2
    }/*,
    {
        menuName: 'groupedAction',
        order: 3
    },
    {
        menuName: 'attachmentsManagement',
        order: 4
    },
    {
        menuName: 'seeBrandings',
        order: 5
    }*/
]