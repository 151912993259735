import { MatMenu } from "@shared/models/mat-menu.model";

/** Table List Component **/

export const tableListMenu: MatMenu[] = [
    {
      icon: 'manage_account',
      label: 'Voir détails',
      value: 'onElementShowDetail',
      color: 'var(--dark)',
      page: 'delivery-status',
      disabled: false
    },
    {
      icon: 'auto_fix_normal',
      label: 'Modifier',
      value: 'onCsvAction',
      color: 'var(--dark)',
      page: 'setting-csv',
      disabled: false
    },
    {
      icon: 'delete',
      label: 'Supprimer',
      value: 'onCsvAction',
      color: 'var(--dark)',
      page: '', // *ngIf="currentPage != 'ops-events' && role != 'admin'"
      disabled: false // limitDateOffset < 0
    },
    {
      icon: 'face',
      label: 'Créer l\'organisateur',
      value: 'generateEoc',
      color: 'var(--dark)',
      page: 'ops-events',
      disabled: false
    },
    {
      icon: 'notifications_active',
      label: 'Notifier l\'organisateur',
      value: 'notifyEocPromoter',
      color: 'var(--dark)',
      page: 'ops-events',
      disabled: false
    },
  ];

export const addressRepertoryMenu: MatMenu[] = [
    {
      icon: 'manage_account',
      label: 'Modifier',
      value: 'onAddressRepertoryAction',
      color: 'var(--dark)',
      page: '',
      disabled: false
    },
    {
      icon: 'delete',
      label: 'Supprimer',
      value: 'onAddressRepertoryAction',
      color: 'var(--dark)',
      page: '',
      disabled: false
    }
];

/** Navbar Component **/

export const profilMenu: MatMenu[] = [
    {
      icon: 'manage_accounts',
      label: 'Mon profil',
      value: 'profil',
      color: 'var(--dark)',
      page: 'navbar',
      disabled: false
    },
    {
      icon: 'logout',
      label: 'Déconnexion',
      value: 'logout',
      color: 'var(--dark)',
      page: 'navbar',
      disabled: false
    }
  ];

export const settingMenu: MatMenu[] = [
    {
      icon: 'local_shipping',
      label: 'Prix de livraison',
      value: 'parametrage/zone',
      color: 'var(--dark)',
      page: 'navbar',
      disabled: false
    },
    {
      icon: 'description',
      label: 'Constructeur de CSV',
      value: 'parametrage/csv',
      color: 'var(--dark)',
      page: 'navbar',
      disabled: false
    },
    {
      icon: 'insights',
      label: "Constructeur d'inforgraphie",
      value: 'parametrage/infographie',
      color: 'var(--dark)',
      page: 'navbar',
      disabled: false
    }
];

/** Bundle Panel Component **/

export const addressMenu: MatMenu[] = [
    {
      icon: 'manage_accounts',
      label: 'Modifier mon bundle',
      value: 'updateBundle',
      color: 'var(--dark)',
      page: 'bundle-panel',
      disabled: false
    },
    {
      icon: 'delete',
      label: 'Supprimer mon bundle',
      value: 'deleteBundle',
      color: 'var(--dark)',
      page: 'bundle-panel',
      disabled: false
    }
];

/** Bundle Table Row Component **/

export const participantMenu: MatMenu[] = [
    {
      icon: 'manage_accounts',
      label: 'Voir détails',
      value: 'createUpdateParticipant',
      color: 'var(--dark)',
      page: 'bundle-table-row',
      disabled: false
    }
];
